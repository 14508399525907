import React, { Component } from 'react';
import StripeWrapper from '../_common/components/forms/StripeWrapper';

const withStripeWrapper = WrappedComponent => class extends Component {
  render() {
    return (
      <StripeWrapper>
        <WrappedComponent {...this.props} />
      </StripeWrapper>
    );
  }
};

export default withStripeWrapper;
