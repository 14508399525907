import TagManager from 'react-gtm-module';

const { REACT_APP_GTM } = process.env;

export const initializeGTM = () => {
  if (REACT_APP_GTM) {
    const tagManagerArgs = {
      gtmId: REACT_APP_GTM,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
