import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from 'rebass';
import Button from './Button';
import Modal from './Modal';

const modalContentStyles = { maxWidth: '400px', padding: '25px 30px' };

const ConfirmationModal = ({
  text, isOpen, onConfirm, onCancel,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    customStyles={{ content: modalContentStyles }}
    onRequestClose={onCancel}
  >
    <Flex flexDirection="column">
      <Text mb={20} fontSize={20}>{text}</Text>
      <Flex>
        <Button mr={20} onClick={onConfirm}>Yes</Button>
        <Button onClick={onCancel}>No</Button>
      </Flex>
    </Flex>
  </Modal>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ConfirmationModal;
