import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

const WhenFieldChanges = ({
  field, becomes, set, to,
}) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={field}>
        {(value) => {
          if (value === becomes) {
            onChange(to);
          }
        }}
      </OnChange>
    )}
  </Field>
);

WhenFieldChanges.propTypes = {
  field: PropTypes.string.isRequired,
  becomes: PropTypes.any,
  set: PropTypes.string.isRequired,
  to: PropTypes.any,
};

export default WhenFieldChanges;
