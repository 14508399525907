import { Box } from 'rebass';
import styled from 'styled-components';

const HrLine = styled(Box)`
  width: 100%;
  height: ${props => props.height};
  background-color: ${props => (props.color ? props.color : props.theme.colors.primaryText)};
`;

HrLine.defaultProps = {
  height: '1px',
};

export default HrLine;
