import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Button, { BUTTON_VARIANT } from '../../_common/components/Button';
import { APP_ROUTES } from '../../_app/routes';

const Wrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.buttonSecondary};
`;

const Image = styled(Box)`
  min-height: 195px;
  height: 100%;
`;

const NoUpcomingBookingsBanner = () => (
  <Wrapper width={1} my={35} flexWrap={['wrap', 'nowrap']}>
    <Flex width={1} flexWrap="wrap" p={20} order={[2, 1]}>
      <Text fontSize={24} color="buttonSecondary" mr="auto" mt={10} mb={20}>
        {"You don't have any upcoming bookings!"}
      </Text>
      <Link to={APP_ROUTES.ROOT}>
        <Button minWidth={220} variant={BUTTON_VARIANT.SECONDARY}>
          Find a room now
        </Button>
      </Link>
    </Flex>
    <Box width={[1, 350]} order={[1, 2]}>
      <Image width={[1, 350]} bg="buttonSecondary" />
    </Box>
  </Wrapper>
);

export default NoUpcomingBookingsBanner;
