import { observable, action } from 'mobx';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../_app/routes';
import API, { setAuthenticationToken } from '../_app/api';
import userStore from './userStore';
import bookingStore from './bookingStore';

export class AuthStore {
  @observable isAuthenticated = !!localStorage.getItem('external-room-token');
  @observable isLoading = true;
  @observable loginModalOpened = false;

  @action toggleLoginModal = () => {
    this.loginModalOpened = !this.loginModalOpened;
  };

  @action
  signIn = async ({ email, password }) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(API_ROUTES.SIGN_IN, { email, password });
      if (this.loginModalOpened) this.toggleLoginModal();
      setAuthenticationToken(token);
      this.isAuthenticated = true;
      userStore.fetchProfile();
    } catch (e) {
      return { [FORM_ERROR]: 'Invalid email or password!' };
    } finally {
      this.isLoading = false;
    }
  };

  @action
  signUp = async (values) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(API_ROUTES.SIGN_UP, values);
      setAuthenticationToken(token);
      this.isAuthenticated = true;
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      return { [FORM_ERROR]: get(e, 'error', 'Invalid email or password!') };
    } finally {
      this.isLoading = false;
    }
  };

  @action logout = async () => {
    this.isAuthenticated = false;
    localStorage.removeItem('external-room-token');
    this.resetAllStores();
  };

  @action resetPassword = async ({ token, password }, successCb) => {
    this.isLoading = true;
    try {
      await API.post(API_ROUTES.RESET_PASSWORD(token), { password });
      successCb();
    } catch (e) {
      return { [FORM_ERROR]: e.message || e.error || 'Error' };
    } finally {
      this.isLoading = false;
    }
  };

  @action onForgotPassword = async ({ email }) => {
    this.isLoading = true;
    try {
      await API.post(API_ROUTES.RESET_PASSWORD(), { email });
      toast.info('We sent you password reset link. Check your email!');
    } catch (e) {
      return { [FORM_ERROR]: e.message || e.error || 'Error' };
    } finally {
      this.isLoading = false;
    }
  };

  @action resetAllStores = () => {
    bookingStore.reset();
    userStore.reset();
  };

  @action validateUser = async (user) => {
    this.isLoading = true;
    try {
      return await API.post(API_ROUTES.VALIDATE, user);
    } catch (e) {
      return {
        [FORM_ERROR]: e.errors
          ? flatten(Object.values(e.errors)).join(' ')
          : e.message,
      };
    } finally {
      this.isLoading = false;
    }
  };
}

export default new AuthStore();
