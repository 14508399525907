import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Flex } from 'rebass';
import TextInput from '../../../_common/components/forms/TextInput';
import { Error } from '../../../_common/components/forms/FormError';
import Button from '../../../_common/components/Button';
import {
  required,
  composeValidators,
  passwordLength,
  passwordsMustMatch,
} from '../../../utils/formValidators';

const ResetPasswordForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex width={1} flexWrap="wrap">
          <Field
            label="Password"
            name="password"
            type="password"
            component={TextInput}
            validate={composeValidators(required, passwordLength(6))}
          />
          <Field
            label="Confirm password"
            name="passwordConfirmation"
            type="password"
            component={TextInput}
            validate={composeValidators(required, passwordsMustMatch)}
          />
        </Flex>
        {submitError && <Error>{submitError}</Error>}
        <Flex width={1} mt={30} flexWrap="wrap" alignItems="flex-start">
          <Button
            type="submit"
            mr={15}
            mb={20}
            loading={submitting}
            disabled={submitting}
          >
            Save
          </Button>
        </Flex>
      </form>
    )}
  />
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
