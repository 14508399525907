import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import FormError from './FormError';

const RadioGroup = ({ children, name }) => (
  <>
    {children}
    <Field name={name} render={({ meta }) => <FormError meta={meta} />} />
  </>
);

RadioGroup.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default RadioGroup;
