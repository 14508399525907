import React from 'react';
import PropTypes from 'prop-types';
import Pikaday from 'pikaday';
import moment from 'moment-timezone';
import { Flex } from 'rebass';
import Label from '../FormLabel';
import { BaseFormInput } from '../TextInput';
import 'pikaday/css/pikaday.css';
import './DatePicker.css';

const { REACT_APP_TZ: TZ } = process.env;

class DatePicker extends React.Component {
  pickerRef = React.createRef();
  picker = null;

  componentDidMount() {
    const { input, disableWeekends, disableDayFn } = this.props;
    this.picker = new Pikaday({
      field: this.pickerRef.current,
      format: 'YYYY-MM-DD',
      onSelect: date => input.onChange(moment.tz(moment(date).format('YYYY-MM-DD'), TZ).format('YYYY-MM-DD')),
      setDefaultDate: true,
      disableWeekends,
      defaultDate: moment
        .tz(input.value === '' ? undefined : input.value, TZ)
        .toDate(),
      disableDayFn,
      firstDay: 1,
    });
  }

  render() {
    const { input, label } = this.props;
    return (
      <Flex flexDirection="column">
        {label && <Label>{label}</Label>}
        <BaseFormInput
          type="text"
          {...input}
          meta={{}}
          ref={this.pickerRef}
          readOnly
          value={moment
            .tz(input.value === '' ? undefined : input.value, TZ)
            .format('DD-MM-YYYY')}
        />
      </Flex>
    );
  }
}
DatePicker.defaultProps = {
  disableDayFn: () => false,
  disableWeekends: false,
};

DatePicker.propTypes = {
  disableWeekends: PropTypes.bool,
  disableDayFn: PropTypes.func,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default DatePicker;
