import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass';
import styled, { css } from 'styled-components';

const HamburgerWrapper = styled(Box)`
  width: 40px;
  height: 30px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  
  > * {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  > span:nth-child(1) {
    top: 0px;
  }

  > span:nth-child(2), > span:nth-child(3) {
    top: 12px;
  }

  > span:nth-child(4) {
    top: 24px;
  }

  ${props => props.isOpened && css`
    > span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
    
    > span:nth-child(2) {
      transform: rotate(45deg);
    }
    
    > span:nth-child(3) {
      transform: rotate(-45deg);
    }
    
    > span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  `}
`;

const Hamburger = ({ isOpened, onClick }) => (
  <HamburgerWrapper isOpened={isOpened} onClick={onClick}>
    <span />
    <span />
    <span />
    <span />
  </HamburgerWrapper>
);

Hamburger.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Hamburger;
