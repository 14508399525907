import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'rebass';
import Modal from '../../_common/components/Modal';
import EditPaymentForm from './forms/EditPaymentForm';

const modalContentStyles = { maxWidth: '400px', padding: '25px 30px' };

const EditPaymentModal = ({ isOpen, onSubmit, onCancel }) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    customStyles={{ content: modalContentStyles }}
    onRequestClose={onCancel}
  >
    <Text mb={20} fontSize={20}>
      Edit payment method
    </Text>

    <EditPaymentForm onSubmit={onSubmit} onCancel={onCancel} />
  </Modal>
);

EditPaymentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditPaymentModal;
