import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import HrLine from '../../_common/components/HrLine';
import Button from '../../_common/components/Button';
import { getSymbolFromCurrency } from '../../utils/currency';

const Photo = styled(Box)`
  background-image: url(${props => props.bgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 172px;
  clip-path: inset(-35% -15% 0% -15% round 100%);
`;

const PriceBreakdown = ({
  duration,
  photo,
  pricePerHour: { currency, gross, net },
  onAgreementClick,
  isLoading,
}) => (
  <Box width={1}>
    <Photo bgSrc={photo} />

    <Flex alignItems="flex-end">
      <Text fontSize={45} fontFamily="VeneerClean">
        {`${getSymbolFromCurrency(currency)}${Number(net).toFixed(0)}`}
      </Text>
      <Text mb={16} fontSize={12} ml="6px">
        +VAT PER HOUR
      </Text>
    </Flex>
    <HrLine height="2px" color="#b1bfe3" />
    <Flex justifyContent="space-between" mt={15}>
      <Text>{`${getSymbolFromCurrency(currency)}${net} x ${duration}hrs`}</Text>
      <Text>{`${getSymbolFromCurrency(currency)}${(net * duration).toFixed(2)}`}</Text>
    </Flex>
    <Flex justifyContent="space-between">
      <Text>VAT</Text>
      <Text>{`${getSymbolFromCurrency(currency)}${((gross - net) * duration).toFixed(2)}`}</Text>
    </Flex>
    <Flex justifyContent="space-between" mb={15}>
      <Text>Total</Text>
      <Text>{`${getSymbolFromCurrency(currency)}${(gross * duration).toFixed(2)}`}</Text>
    </Flex>
    <HrLine height="2px" color="#b1bfe3" />
    <Flex justifyContent="space-between" mt={15}>
      <Text>Deposit (due now)</Text>
      <Text>{`${getSymbolFromCurrency(currency)}${(gross * duration * 0.2).toFixed(2)}`}</Text>
    </Flex>
    <Flex justifyContent="space-between">
      <Text>Final payment</Text>
      <Text>{`${getSymbolFromCurrency(currency)}${(gross * duration * 0.8).toFixed(2)}`}</Text>
    </Flex>
    <Flex>
      <Button
        width={1}
        mt={20}
        onClick={() => onAgreementClick()}
        loading={isLoading}
      >
        Agree & continue
      </Button>
    </Flex>
  </Box>
);

PriceBreakdown.propTypes = {
  photo: PropTypes.string,
  duration: PropTypes.number,
  pricePerHour: PropTypes.object,
  onAgreementClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

PriceBreakdown.defaultProps = {
  pricePerHour: {},
};

export default PriceBreakdown;
