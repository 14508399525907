import React from 'react';
import { Field } from 'react-final-form';
import { Flex } from 'rebass';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import StripeInput from '../../../_common/components/forms/StripeInput';
import TextInput from '../../../_common/components/forms/TextInput';
import {
  required,
  composeValidators,
  mustBeValidStripeElement,
  mustBeValidEmail,
} from '../../../utils/formValidators';

const PaymentDetailsFields = () => (
  <>
    <Flex width={1} flexDirection="column">
      <Field
        label="Name"
        name="name"
        component={TextInput}
        validate={required}
      />
      <Field
        label="Company name"
        name="companyName"
        component={TextInput}
      />
      <Field
        label="Address"
        name="address"
        component={TextInput}
        validate={required}
      />
      <Field
        label="Contact number"
        name="phone"
        component={TextInput}
        validate={required}
      />
      <Field
        label="Email"
        name="email"
        component={TextInput}
        validate={composeValidators(required, mustBeValidEmail)}
      />
      <Field
        name="cardNumber"
        validate={composeValidators(required, mustBeValidStripeElement)}
      >
        {({ input, meta }) => (
          <StripeInput
            label="Card number"
            component={CardNumberElement}
            input={input}
            meta={meta}
            onChange={input.onChange}
          />
        )}
      </Field>
      <Field
        name="cardCvc"
        validate={composeValidators(required, mustBeValidStripeElement)}
      >
        {({ input, meta }) => (
          <StripeInput
            label="Security code"
            component={CardCVCElement}
            input={input}
            meta={meta}
            onChange={input.onChange}
          />
        )}
      </Field>
      <Field
        name="cardExpiry"
        validate={composeValidators(required, mustBeValidStripeElement)}
      >
        {({ input, meta }) => (
          <StripeInput
            label="Expires on"
            placeholder="MM / YY"
            component={CardExpiryElement}
            input={input}
            meta={meta}
            onChange={input.onChange}
          />
        )}
      </Field>
    </Flex>
  </>
);

export default PaymentDetailsFields;
