import { extendObservable, action } from 'mobx';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const initialState = {
  isLoading: false,
  error: null,
  upcomingBookings: [],
  cancelledBookings: [],
  pastBookings: [],
  links: {},
  currentBooking: {},
};

export class BookingStore {
  constructor() {
    extendObservable(this, initialState);
  }
  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetchBookings = async (refetch = false) => {
    if (refetch) {
      this.upcomingBookings = [];
      this.pastBookings = [];
      this.cancelledBookings = [];
    }
    this.isLoading = true;
    try {
      const {
        data: {
          upcomingBookings, pastBookings, cancelledBookings = [], links,
        },
      } = await API(`${API_ROUTES.BOOKINGS()}${get(this.links, 'next', '').replace('/bookings', '')}`);
      this.upcomingBookings = [...this.upcomingBookings, ...upcomingBookings];
      this.pastBookings = [...this.pastBookings, ...pastBookings];
      this.cancelledBookings = [...this.cancelledBookings, ...cancelledBookings];
      this.links = links;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchBookingById = async (id) => {
    this.isLoading = true;
    try {
      const { data } = await API(`${API_ROUTES.BOOKINGS(id)}`);
      this.currentBooking = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteBooking = async (id) => {
    this.isLoading = true;
    try {
      await API.delete(`${API_ROUTES.BOOKINGS(id)}`);
      this.currentBooking = {};
      const booking = this.upcomingBookings.find(upcomingBooking => upcomingBooking._id === id); // eslint-disable-line
      if (booking) {
        this.cancelledBookings.unshift({ ...booking, cancelled: true });
      }
      this.upcomingBookings = this.upcomingBookings.filter(
        // eslint-disable-next-line no-underscore-dangle
        upcomingBooking => upcomingBooking._id !== id,
      );
      toast.success('Successfully cancelled booking!');
    } catch (e) {
      this.error = e;
      toast.success("Couldn't delete booking!");
    } finally {
      this.isLoading = false;
    }
  };

  @action editPaymentMethod = async (bookingId, createStripeToken, toggleModal) => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { token } = await createStripeToken();
      const stripeToken = get(token, 'id');
      await API.post(API_ROUTES.PAYMENT_METHOD(bookingId), { stripeToken });
      toast.success('You have successfully updated your payment method.');
      toggleModal(false);
    } catch (e) {
      toast.error(e.message || e.error || 'Couldn\'t edit payment method. Please, try again later.');
    } finally {
      this.isLoading = false;
    }
  };

  @action updateBooking = async (bookingId, from, to) => {
    this.isLoading = true;
    try {
      await API.patch(`${API_ROUTES.UPDATE_BOOKING(bookingId)}`, {
        from,
        to,
      });
      toast.success('Successfully updated booking!');
    } catch (e) {
      this.error = e;
      toast.error(e.error || e.message || 'Couldn\'t update booking...');
    } finally {
      this.isLoading = false;
    }
  };
}

export default new BookingStore();
