import React from 'react';
import PropTypes from 'prop-types';
import BaseLayout from './BaseLayout';

const SignInLayout = ({ children, ...props }) => (
  <BaseLayout bgColor="#212840" {...props} pt={100}>
    {children}
  </BaseLayout>
);

SignInLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignInLayout;
