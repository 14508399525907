import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';

class StripeWrapper extends Component {
  state = {
    stripe: null,
  };

  componentDidMount() {
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
      });
    } else {
      document.querySelector('#stripe').addEventListener('load', () => {
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
        });
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>{this.props.children}</Elements>
      </StripeProvider>
    );
  }
}

StripeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StripeWrapper;
