export const FONT_FAMILY = {
  VENEER_CLEAN: 'VeneerClean',
  HAILSR_BOLD: 'HalisR-Bold',
  HAILSR_BOOK: 'HalisR-Book',
  HAILSR_MEDIUM: 'HalisR-Medium',
};

export default {
  colors: {
    mainBg: '#F0F2FA',
    primaryText: '#212840',
    secondaryBg: '#212840',
    secondaryText: '#f8b674',
    border: '#bfbfc6',
    main: '#000',
    error: '#a94442',
    mainOrange: '#fabe79',
    secondaryOrange: '#FFD98E',
    buttonSecondary: '#b1bfe3',
  },
  fonts: {
    [FONT_FAMILY.VENEER_CLEAN]: 'VeneerClean',
    [FONT_FAMILY.HAILSR_BOLD]: 'HalisR-Bold',
    [FONT_FAMILY.HAILSR_BOOK]: 'HalisR-Book',
  },
};
