import PropTypes from 'prop-types';
import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../../../_app/routes';
import Button from '../Button';
import Hamburger from './Hamburger';

const MenuItem = ({
  iconName, label, to,
}) => (
  <NavLink
    to={to}
    style={{ textDecoration: 'none', color: '#fff' }}
    activeStyle={{ color: '#fabe79', fill: '#fabe79' }}
  >
    <Flex alignItems="center" mx={10} style={{ height: '100%' }}>
      {iconName && <Image src={`/images/icons/${iconName}.png`} width={30} height="auto" mr={12} />}
      <Text
        style={{
          transition: 'all .3s',
        }}
        lineHeight={1}
        fontSize={18}
      >
        {label}
      </Text>
    </Flex>
  </NavLink>
);

MenuItem.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const MobileMenu = styled(Flex)`
  position: fixed;
  top: 67px;
  bottom: 0;
  right: 0;
  z-index: 1100;
  width: 275px;
  transition: all 0.5s ease 0s;
  background-color: ${props => props.theme.colors.secondaryBg};
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
`;

export const HideMobile = styled(Box)`
  @media screen and (max-width: 64em) {
    display: none;
  }
`;

export const HideDesktop = styled(Box)`
  @media screen and (min-width: 64em) {
    display: none;
  }
`;

const duration = 300;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  right: 0,
};

const transitionStyles = {
  entering: { right: 0 },
  entered: { right: 0 },
  exiting: { right: -275 },
  exited: { right: -275 },
};

const Menu = ({ logout }) => {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  return (
    <Fragment>
      <HideMobile>
        <Flex>
          <MenuItem iconName="calendar" label="BOOKINGS" to={APP_ROUTES.BOOKINGS} />
          <MenuItem iconName="profile" label="ACCOUNT" to={APP_ROUTES.ACCOUNT} />
          {false && <MenuItem iconName="payment" label="PAYMENTS" to={APP_ROUTES.PAYMENTS} />}
          <Button ml={20} mr={40} py="8px" onClick={logout}>
            Log out
          </Button>
        </Flex>
      </HideMobile>
      <HideDesktop>
        <Box mr={[10, 30]} ref={ref}>
          <Hamburger isOpened={isOpened} onClick={() => setIsOpened(!isOpened)} />
          <Transition in={isOpened} timeout={duration}>
            {state => (
              <MobileMenu style={{ ...defaultStyle, ...transitionStyles[state] }}>
                <Flex alignItems="center" my={15} onClick={() => setIsOpened(false)}>
                  <MenuItem color="primaryText" iconName="calendar" label="BOOKINGS" to={APP_ROUTES.BOOKINGS} />
                </Flex>
                <Flex alignItems="center" my={15} onClick={() => setIsOpened(false)}>
                  <MenuItem color="primaryText" iconName="profile" label="ACCOUNT" to={APP_ROUTES.ACCOUNT} />
                </Flex>
                {false && (
                  <Flex alignItems="center" my={15} onClick={() => setIsOpened(false)}>
                    <MenuItem color="primaryText" iconName="payment" label="PAYMENTS" to={APP_ROUTES.PAYMENTS} />
                  </Flex>
                )}
                <Button ml={20} mt={15} py="8px" onClick={logout}>
                    Log out
                </Button>
              </MobileMenu>
            )}
          </Transition>
        </Box>
      </HideDesktop>
    </Fragment>
  );
};

Menu.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Menu;
