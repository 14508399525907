import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FORM_ERROR } from 'final-form';
import SignUpFirstStepForm from './SignUpFirstStepForm';
import SignUpLastStepForm from './SignUpLastStepForm';

// refactor after feedback
const SignUpWizard = ({ onSubmit, keepGuestData, validateUser }) => {
  const [isLastStep, setLastStep] = useState(false); // eslint-disable-line
  const [firstStepValues, setFirstStepValues] = useState({}); // eslint-disable-line

  return (
    <>
      {!isLastStep && (
        <SignUpFirstStepForm
          initialValues={firstStepValues}
          onSubmit={async (values) => {
            // eslint-disable-line
            setFirstStepValues(values);
            const result = await validateUser(values);
            if (!result[FORM_ERROR]) {
              setLastStep(true);
            }
            return result;
          }}
          keepGuestData={keepGuestData}
        />
      )}
      {isLastStep && (
        <SignUpLastStepForm
          initialValues={firstStepValues}
          onSubmit={onSubmit}
          onCancel={() => setLastStep(false)}
        />
      )}
    </>
  );
};

SignUpWizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  keepGuestData: PropTypes.func,
  validateUser: PropTypes.func.isRequired,
};

export default SignUpWizard;
