import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { Box, Text as TextRaw, Flex } from 'rebass';
import TypeWriter from 'react-typewriter';

const Wrapper = styled(Box)`
  background-color: ${props => props.theme.colors.secondaryBg};
  color: #fff;
  position: relative;
  z-index: 1;
  height: 200px;
  display: flex;
  clip-path: inset(-35% -5% 0% -5% round 100%);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 2px;
  padding-bottom: 35px;
  margin-bottom: -100px;
  z-index: 10;
  font-family: 'VeneerClean';
  font-size: 51px;
  line-height: 1;
  transition: all .6s ease-in-out;
  @media screen and (max-width: 40em) {
    border-radius: 0;
    font-size: 30px;
  }
`;

const Text = styled(TextRaw)`
  opacity: 1;
  transition: opacity .6s ease-in-out;
  
  ${props => props.visible && css`
    opacity: 0;
  `}
`;

const SubHeader = ({ titles, ...props }) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.reset();
  }, [titles]);

  return (
    <Wrapper {...props}>
      <TypeWriter ref={ref} typing={1} minDelay={80} maxDelay={80}>
        <Flex alignItems="center" flexDirection="column">
          <Text color="secondaryText">{get(titles, '[0]')}</Text>
          <Flex>
            <Text>
              {get(titles, '[1]')}
            </Text>
            <Text color="secondaryText">.</Text>
          </Flex>
        </Flex>
      </TypeWriter>
    </Wrapper>
  );
};

SubHeader.propTypes = {
  firstTitle: PropTypes.string,
  secondTitle: PropTypes.string,
  titles: PropTypes.array.isRequired,
};

export default SubHeader;
