import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import TextInput from '../../../_common/components/forms/TextInput';
import UnderlineButton from '../../../_common/components/UnderlineButton';
import { Error } from '../../../_common/components/forms/FormError';
import Button from '../../../_common/components/Button';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from '../../../utils/formValidators';

const SignInForm = ({ onSubmit, toggleForm }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex width={1} alignItems="center" flexDirection="column">
          <Field
            label="Email"
            name="email"
            component={TextInput}
            validate={composeValidators(required, mustBeValidEmail)}
          />
          <Field
            label="Password"
            name="password"
            type="password"
            component={TextInput}
            validate={required}
          />
          {submitError && <Error>{submitError}</Error>}
          <Flex width={1} justifyContent="flex-end" mt={10}>
            <UnderlineButton type="button" onClick={() => toggleForm(true)}>
              Forgot password?
            </UnderlineButton>
          </Flex>
          <Box width={1}>
            <Button type="submit" loading={submitting} disabled={submitting}>
              Log in
            </Button>
          </Box>
        </Flex>
      </form>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
};

export default SignInForm;
