import React from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormError from './FormError';
import Label from './FormLabel';

export const BaseFormInput = styled.input`
  display: block;
  height: 30px;
  background-color: #ffffff;
  color: #5b5b5b;
  border: solid 1px ${props => props.theme.colors.mainOrange};
  padding: 20px 11px;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 16px;
  width: 100%;
  &:focus {
    font-style: normal;
    color: #212840;
    box-shadow: 0 0 5px ${props => props.theme.colors.mainOrange};
  }
`;

const TextInput = ({
  input, meta, label, width, ...props
}) => (
  <Flex width={width} flexDirection="column">
    {label && <Label>{label}</Label>}
    <BaseFormInput {...props} {...input} />
    <FormError meta={meta} />
  </Flex>
);

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  width: '100%',
};

export default TextInput;
