import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Flex, Text } from 'rebass';
import HorizontalLine from '../../_common/components/HorizontalLine';
import Loader from '../../_common/components/Loader';
import { FONT_FAMILY } from '../../theme';
import BookingListItem from './BookingListItem';
import NoUpcomingBookingsBanner from './NoUpcomingBookingsBanner';

const BookingsList = ({
  deleteBooking,
  updateBooking,
  upcomingBookings,
  cancelledBookings,
  pastBookings,
  isLoading,
  editPaymentMethod,
}) => (
  <Flex flexDirection="column" width={1} mb={70}>
    <Text mt={70} mb={35} fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={51} color="mainOrange">UPCOMING BOOKINGS</Text>
    {!isEmpty(upcomingBookings) ? (
      <>
        {upcomingBookings.map(({ invoiceUrl, ...booking }, i) => (
          <BookingListItem
            {...booking}
            deleteBooking={deleteBooking}
            updateBooking={updateBooking}
            editPaymentMethod={editPaymentMethod}
            key={`upcoming-user-booking-${i}`}
          />
        ))}
      </>
    ) : (
      <NoUpcomingBookingsBanner />
    )}
    {!isEmpty(pastBookings) && (
      <>
        <HorizontalLine mt={20} mb={60} />
        <Text mb={35} fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={51} color="mainOrange">PAST BOOKINGS</Text>
        {pastBookings.map((booking, i) => (
          <BookingListItem {...booking} key={`upcoming-user-booking-${i}`} />
        ))}
      </>
    )}
    {!isEmpty(cancelledBookings) && (
      <>
        <HorizontalLine mt={20} mb={60} />
        <Text mb={35} fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={51} color="mainOrange">CANCELLED BOOKINGS</Text>
        {cancelledBookings.map((booking, i) => (
          <BookingListItem {...booking} key={`upcoming-user-booking-${i}`} />
        ))}
      </>
    )}
    {isLoading && <Loader fullPage={false} />}
  </Flex>
);

BookingsList.propTypes = {
  upcomingBookings: PropTypes.array.isRequired,
  pastBookings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  editPaymentMethod: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  cancelledBookings: PropTypes.array,
};

export default BookingsList;
