import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { observable } from 'mobx';
import get from 'lodash/get';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Card from '../_common/components/Card';
import DirectionButton from '../_common/components/DirectionButton';
import Loader from '../_common/components/Loader';
import VerticalDottedLine from '../_common/components/VerticalDottedLine';
import Location from '../_common/components/Location';
import CardTitle from '../signIn/components/CardTitle';
import DateSummary from './components/DateSummary';
import Button, { BUTTON_VARIANT } from '../_common/components/Button';
import { APP_ROUTES } from '../_app/routes';
import PriceBreakdown from './components/PriceBreakdown';

const Container = styled(Box)`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
`;
const VerticalLine = styled(VerticalDottedLine)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const { REACT_APP_TZ: TZ } = process.env;

@inject('searchStore', 'cartStore', 'routerStore')
@observer
class ResourceBookingConfirmation extends Component {
  @observable isModalOpened = false;

  componentDidMount() {
    const {
      searchStore: { fetchResourceById },
      match: {
        params: { resourceId },
      },
    } = this.props;
    fetchResourceById(resourceId);
  }

  render() {
    const {
      location: { search },
      searchStore: { currentResource, mapFilters, isLoading },
      cartStore: { addBooking, isLoading: isCreatingBooking },
    } = this.props;
    const filters = queryString.parse(search);

    const duration = moment.duration(
      moment
        .tz(`${filters.date} ${filters.to}`, TZ)
        .diff(moment.tz(`${filters.date} ${filters.from}`, TZ)),
    );
    const hoursDiff = duration.asHours();
    const capacity = get(currentResource, 'additionalInfo.capacity');
    return (
      <Container>
        <Card px={35} py={30} mt={60}>
          {isLoading && <Loader />}
          <Flex width={1} flexWrap="wrap">
            <Flex width={[1, 1, 0.7]}>
              <Box width={1} pr={[0, 0, 15]}>
                <CardTitle>{currentResource.name}</CardTitle>
                <Location pt={10}>{currentResource.district}</Location>
                <DateSummary {...filters} capacity={capacity} />
                <Link to={APP_ROUTES.SEARCH(search)}>
                  <Button variant={BUTTON_VARIANT.SECONDARY}>
                    Amend details
                  </Button>
                </Link>
              </Box>
              <VerticalLine ml="auto" />
            </Flex>
            <Box width={[1, 1, 0.3]} pl={[0, 0, 20]} mt={[20, 20, 0]}>
              <PriceBreakdown
                pricePerHour={currentResource.pricePerHour}
                duration={hoursDiff}
                photo={get(currentResource.photo, 'default.url')}
                isLoading={isCreatingBooking}
                onAgreementClick={() => addBooking({
                  ...currentResource,
                  ...mapFilters(filters),
                })
                }
              />
            </Box>
          </Flex>
        </Card>
        <DirectionButton
          mt={20}
          px={0}
          onClick={() => this.props.routerStore.goBack()}
        >
          Take me back
        </DirectionButton>
      </Container>
    );
  }
}

ResourceBookingConfirmation.wrappedComponent.propTypes = {
  searchStore: PropTypes.shape({
    fetchResourceById: PropTypes.func.isRequired,
    currentResource: PropTypes.object.isRequired,
    mapFilters: PropTypes.func.isRequired,
  }).isRequired,
  cartStore: PropTypes.shape({
    addBooking: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  routerStore: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResourceBookingConfirmation;
