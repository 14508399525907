import React from 'react';
import { Flex, Image } from 'rebass';
import StyledAnchor from './StyledAnchor';
import theme from '../../theme';

const Footer = ({ ...props }) => (
  <Flex
    {...props}
    bg="secondaryBg"
    justifyContent="space-between"
    px={30}
    alignItems="center"
    py={20}
    width={1}
  >
    <Image height={18} src="/images/wl-logo-orange.svg" />
    <StyledAnchor href="https://work.life/privacy/" hoverEffect color={theme.colors.secondaryText} fontSize={11} lineHeight={1.64} letterSpacing="-0.2px">
    Privacy policy

    </StyledAnchor>
  </Flex>
);

export default Footer;
