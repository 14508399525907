import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import BookingsList from './components/BookingsList';
import Button from '../_common/components/Button';

@inject('bookingStore')
@observer
class BookingsPage extends Component {
  componentDidMount() {
    this.props.bookingStore.fetchBookings(true);
  }
  render() {
    const {
      upcomingBookings,
      pastBookings,
      isLoading,
      fetchBookings,
      deleteBooking,
      links,
      editPaymentMethod,
      cancelledBookings,
      updateBooking,
    } = this.props.bookingStore;
    return (
      <Flex flexDirection="column" alignItems="center">
        <BookingsList
          upcomingBookings={upcomingBookings}
          pastBookings={pastBookings}
          deleteBooking={deleteBooking}
          updateBooking={updateBooking}
          cancelledBookings={cancelledBookings}
          isLoading={isLoading}
          editPaymentMethod={editPaymentMethod}
        />
        {links.next && (
          <Button my={80} onClick={() => fetchBookings()}>
            Load more
          </Button>
        )}
      </Flex>
    );
  }
}

BookingsPage.wrappedComponent.propTypes = {
  bookingStore: PropTypes.shape({
    upcomingBookings: PropTypes.array.isRequired,
    pastBookings: PropTypes.array.isRequired,
    fetchBookings: PropTypes.func.isRequired,
    deleteBooking: PropTypes.func.isRequired,
    cancelledBookings: PropTypes.array,
    links: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    editPaymentMethod: PropTypes.func.isRequired,
    updateBooking: PropTypes.func.isRequired,
  }).isRequired,
};

export default BookingsPage;
