import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DirectionButton from '../_common/components/DirectionButton';
import { APP_ROUTES } from '../_app/routes';
import Card from '../_common/components/Card';
import Button from '../_common/components/Button';
import CardTitle from '../signIn/components/CardTitle';
import ResetPasswordForm from './components/forms/ResetPasswordForm';

const Wrapper = styled(Flex)`
  max-width: 500px;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

@inject('authStore', 'routerStore')
@observer
class ResetPasswordPage extends Component {
  @observable isPasswordSet = false;

  onSubmit = async ({ password }) => {
    const { token } = this.props.match.params;
    return this.props.authStore.resetPassword({ token, password }, () => {
      this.isPasswordSet = true;
    });
  };

  render() {
    return (
      <Wrapper width={1} mx="auto">
        <Card>
          {this.isPasswordSet ? (
            <>
              <CardTitle>Password successfully set</CardTitle>
              <Text my={30} fontSize={20}>
                You can now login
              </Text>
              <Link to={APP_ROUTES.SIGN_IN}>
                <Button>Login</Button>
              </Link>
            </>
          ) : (
            <>
              <CardTitle>Reset your password</CardTitle>
              <ResetPasswordForm onSubmit={this.onSubmit} />
            </>
          )}
        </Card>
        <DirectionButton
          px={0}
          mt={15}
          onClick={() => this.props.routerStore.push(APP_ROUTES.ROOT)}
        >
          Take me back
        </DirectionButton>
      </Wrapper>
    );
  }
}

ResetPasswordPage.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    resetPassword: PropTypes.func,
  }).isRequired,
  routerStore: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.object,
};

export default ResetPasswordPage;
