import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Flex, Box, Text } from 'rebass';
import { Link } from 'react-router-dom';
import TextInput from '../../../_common/components/forms/TextInput';
import FormCheckbox from '../../../_common/components/forms/FormCheckbox';
import { Error } from '../../../_common/components/forms/FormError';
import Button from '../../../_common/components/Button';
import { APP_ROUTES } from '../../../_app/routes';
import { FONT_FAMILY } from '../../../theme';
import {
  required,
  composeValidators,
  mustBeValidEmail,
  // passwordLength,
  // passwordsMustMatch,
} from '../../../utils/formValidators';

const formRef = React.createRef();
const SignUpFirstStepForm = ({ onSubmit, keepGuestData, initialValues }) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    ref={formRef}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex width={1} flexWrap="wrap">
          <Box width={[1, 0.5]} pr={[0, '5px']}>
            <Field
              label="First name"
              name="firstName"
              component={TextInput}
              validate={required}
            />
          </Box>
          <Box pl={[0, '5px']} width={[1, 0.5]}>
            <Field
              label="Last name"
              name="lastName"
              component={TextInput}
              validate={required}
            />
          </Box>
        </Flex>
        <Field
          label="Email"
          name="email"
          component={TextInput}
          validate={composeValidators(required, mustBeValidEmail)}
        />
        <Flex width={1} flexWrap="wrap">
          <Box width={[1, 0.5]} pr={[1, '5px']}>
            <Field
              label="Phone number"
              name="phone"
              component={TextInput}
              validate={required}
            />
          </Box>
          <Box pl={[0, '5px']} width={[1, 0.5]}>
            <Field label="Company name" name="company" component={TextInput} />
          </Box>
        </Flex>
        <Field
          label={(
            <Text fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK}>
              Tick to agree to receiving promotional info from Work.Life
            </Text>
)}
          name="promotionalInfoAgreement"
          type="checkbox"
          id="aggreement"
          component={FormCheckbox}
        />
        {submitError && <Error>{submitError}</Error>}
        <Flex width={1} mt={30} flexWrap="wrap" alignItems="flex-start">
          <Button
            type="submit"
            mr={15}
            mb={20}
            py="7px"
            loading={submitting}
            disabled={submitting}
          >
            Create account
          </Button>
          <Link to={APP_ROUTES.BOOKING_PAYMENT}>
            <Button
              type="button"
              py="7px"
              onClick={() => {
                const values = get(formRef.current, 'state.state.values');
                keepGuestData(values);
              }}
            >
              Continue as guest
            </Button>
          </Link>
        </Flex>
      </form>
    )}
  />
);

SignUpFirstStepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  keepGuestData: PropTypes.func.isRequired,
};

export default SignUpFirstStepForm;
