import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Button from '../_common/components/Button';
import BookingEditForm from './components/forms/BookingEditForm';

@inject('bookingStore')
@observer
class BookingEditPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { bookingId },
      },
      bookingStore: { fetchBookingById },
    } = this.props;
    fetchBookingById(bookingId);
  }
  render() {
    const {
      match: {
        params: { bookingId },
      },
      bookingStore: { deleteBooking, isLoading },
    } = this.props;
    return (
      <>
        <h1>{`Edit booking: ${bookingId}`}</h1>
        <Button disabled={isLoading} onClick={() => deleteBooking(bookingId)}>
          Delete
        </Button>
        <BookingEditForm />
      </>
    );
  }
}

BookingEditPage.wrappedComponent.propTypes = {
  bookingStore: PropTypes.shape({
    upcomingBookings: PropTypes.array.isRequired,
    pastBookings: PropTypes.array.isRequired,
    fetchBookings: PropTypes.func.isRequired,
    links: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  match: PropTypes.object.isRequired,
};

export default BookingEditPage;
