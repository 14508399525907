import { APP_ROUTES } from './routes';

export const MEETING_ROOM_EMAIL = 'meetings@work.life';

export const SUBHEADER_TITLES = {
  [APP_ROUTES.ROOT]: ['meeting rooms to...', 'land your next big client'],
  [APP_ROUTES.SEARCH()]: ['take your pick...', 'from these available rooms'],
  [APP_ROUTES.SIGN_IN]: ['log in...', 'or create an account'],
  [APP_ROUTES.BOOKING_PAYMENT]: ['time to pay...', 'for your bookings'],
  [APP_ROUTES.RESOURCE_BOOKING_CONFIRMATION()]: ['what a great choice...', 'if we say so ourselves'],
  [APP_ROUTES.BOOKINGS_SUCCESS]: ['all booked!', 'see you soon'],
};

export const FACILITIES = [
  {
    name: 'Unlimited Tea and Fresh Ground Coffee',
    shortName: 'Hot drinks',
    icon: '/images/icons/facilities/coffee-cup.png',
  },
  {
    name: 'Ninja Fast Wifi',
    shortName: 'Wifi',
    icon: '/images/icons/facilities/wifi.png',
  },
  {
    name: 'TV for Screensharing from Apple, Android or Windows',
    shortName: 'TV',
    icon: '/images/icons/facilities/tv.png',
  },
  {
    name: 'Whiteboard and Pens',
    shortName: 'Whiteboard',
    icon: '/images/icons/facilities/whiteboard.png',
  },
  {
    name: 'Snacks in Every Room',
    shortName: 'Snacks',
    icon: '/images/icons/facilities/snacks.png',
  },
];
