import times from 'lodash/times';
import head from 'lodash/head';
import last from 'lodash/last';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import { APP_ROUTES } from '../../../_app/routes';
import Button from '../../../_common/components/Button';
import DatePicker from '../../../_common/components/forms/DatePicker';
import FormCheckbox from '../../../_common/components/forms/FormCheckbox';
import { AbsolutePositionedErrorsForm } from '../../../_common/components/forms/FormError';
import FormSelect from '../../../_common/components/forms/FormSelect';
import GooglePlacesAutocomplete from '../../../_common/components/forms/GoogleAutocomplete';
import WhenFieldChanges from '../../../_common/components/forms/WhenFieldChanges';
import { HideDesktop, HideMobile } from '../../../_common/components/navigation/Menu';
import StyledAnchor from '../../../_common/components/StyledAnchor';
import {
  composeValidators,
  mustBe1hourDuration,
  mustBeLaterThanCurrentHour,
  required,
} from '../../../utils/formValidators';
import { BOOKING_HOURS, determineBookingsHoursSearch } from '../../../utils/hoursHelpers';

const CAPACITY = times(13, n => n + 1).map(n => ({
  value: n.toString(),
  label: `${n <= 12 ? n : 12} ${n === 1 ? 'person' : (n <= 12 ? 'people' : '+')}`,
}));

const { REACT_APP_TZ: TZ } = process.env;

const bookingHours = BOOKING_HOURS(9, 16.5);
const checkoutHours = BOOKING_HOURS(10, 17.5);

const SearchForm = ({ history: { push }, initialValues }) => (
  <Form
    initialValues={{ ...initialValues, ...determineBookingsHoursSearch() }}
    onSubmit={(values) => {
      if (!values.location) {
        delete values.placeId;
      }
      push(APP_ROUTES.SEARCH(`${queryString.stringify({ ...values })}`));
    }}

    render={({ handleSubmit, form: { change } }) => (
      <AbsolutePositionedErrorsForm onSubmit={handleSubmit}>
        <Flex alignItems="center" flexDirection="column">
          <Flex alignItems="flex-end" mb={[10, 10, 20]} flexWrap="wrap" width={[1, 1, 'initial']}>
            <HideMobile>
              <Text color="mainOrange" fontSize={29} mr={10}>
                {'I\'m looking for a room in'}
              </Text>
            </HideMobile>
            <HideDesktop>
              <Text color="mainOrange" fontSize={29} mb={[10, 10, 0]} mr={10} width={['100%', 500, 'initial']}>
                {'I\'m looking for a room...'}
              </Text>
            </HideDesktop>
            <Box width={[1 / 2, 1 / 2, 169]} pr={[10, 10, 0]} mx={[0, 0, 10]}>
              <GooglePlacesAutocomplete
                name="location"
                placeholder="Location"
                label="WHERE"
                formChange={change}
                placeId={initialValues.placeId}
              />
            </Box>
            <HideMobile>
              <Text color="mainOrange" fontSize={29} mx={10}>
                for
              </Text>
            </HideMobile>
            <Box pl={[10, 10, 0]} width={[1 / 2, 1 / 2, 169]} mx={[0, 0, 10]}>
              <Field
                width={1}
                name="capacity"
                component={FormSelect}
                label="HOW MANY"
                validate={required}
                options={CAPACITY}
              />
            </Box>
          </Flex>
          <Flex alignItems="flex-end" mb={40} flexWrap="wrap" width={[1, 1, 'initial']}>
            <HideMobile>
              <Text color="mainOrange" fontSize={29} mr={10}>
                on
              </Text>
            </HideMobile>
            <Box width={[1, 1, 169]} mb={[10, 10, 0]} mx={[0, 0, 10]}>
              <Field
                name="date"
                label="DATE"
                component={DatePicker}
                disableDayFn={date => moment.tz(date, TZ).isBefore(moment.tz(TZ).format('YYYY-MM-DD'))}
                disableWeekends
              />
            </Box>
            <HideMobile>
              <Text color="mainOrange" fontSize={29} mx={10}>
                from
              </Text>
            </HideMobile>
            <Box width={[1 / 2, 1 / 2, 169]} pr={[10, 10, 0]} mb={[10, 0, 0]} mx={[0, 0, 10]}>
              <Field
                name="from"
                component={FormSelect}
                label="CHECK-IN"
                width={1}
                validate={composeValidators(
                  required,
                  mustBeLaterThanCurrentHour,
                )}
                onChange={() => {
                  change('isWholeDay', false);
                }}
                options={bookingHours}
              />
            </Box>
            <HideMobile>
              <Text color="mainOrange" fontSize={29} mx={10}>
                to
              </Text>
            </HideMobile>
            <Box mr={[0, 0, 20]} width={[1 / 2, 1 / 2, 169]} pl={[10, 10, 0]} mb={[10, 0, 0]} mx={[0, 0, 10]}>
              <Field
                name="to"
                label="CHECK-OUT"
                width={1}
                component={FormSelect}
                validate={composeValidators(required, mustBe1hourDuration)}
                onChange={() => {
                  change('isWholeDay', false);
                }}
                options={checkoutHours}
              />
            </Box>
            <Box mb={-9} ml={[0, 0, 10]}>
              <Field
                label={<Text fontSize={13}>TICK IF WHOLE DAY</Text>}
                name="isWholeDay"
                type="checkbox"
                id="isWholeDay"
                component={FormCheckbox}
              />
            </Box>
            <WhenFieldChanges field="isWholeDay" becomes set="from" to={head(bookingHours).value} />
            <WhenFieldChanges field="isWholeDay" becomes set="to" to={last(checkoutHours).value} />
          </Flex>
          <Button mb={15} type="submit">
            Find me a room!
          </Button>
          <Text fontSize={13}>
            Want to book multiple days?
            {' '}
            <StyledAnchor fontSize={13} href="mailto:meetings@work.life">
              Contact us
            </StyledAnchor>
          </Text>
        </Flex>
      </AbsolutePositionedErrorsForm>
    )}
  />
);
SearchForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchForm);
