/* eslint-disable no-underscore-dangle */
import { observable, action, computed } from 'mobx';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import API from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';
import { getSymbolFromCurrency } from '../utils/currency';
import router from './routerStore';

const CART_ID = 'cartId';
const STRIPE_FIELDS = ['cardCvc', 'cardExpiry', 'cardNumber'];
const { REACT_APP_TZ: TZ } = process.env;

let cartExpirationTimeout = null;

export class CartStore {
  @observable items = [];
  @observable cartId = null;
  @observable isLoading = true;
  @observable error = '';
  @observable isPaid = false;
  @observable discountCode = {
    isLoading: false,
    message: '',
    data: {},
  };

  @action fetchCart = async () => {
    this.cartId = localStorage.getItem(CART_ID);
    if (this.cartId) {
      this.isLoading = true;
      try {
        const {
          data: {
            order: { bookings, ttl, state },
          },
        } = await API(API_ROUTES.ORDERS(this.cartId));
        if (state === 'complete') {
          this.clearCart();
        }
        this.items = bookings;
        this.scheduleCartExpiration(ttl);
      } catch (e) {
        this.cartId = null;
        localStorage.removeItem(CART_ID);
      } finally {
        this.isLoading = false;
      }
    }
    this.isLoading = false;
  };

  @action scheduleCartExpiration = async (ttl) => {
    clearTimeout(cartExpirationTimeout);
    const diff = moment.tz(ttl, TZ).diff(moment.tz(TZ));
    cartExpirationTimeout = setTimeout(() => {
      toast.info('We are sorry, but your order has expired. Please, try again.');
      router.push(APP_ROUTES.ROOT);
      this.clearCart();
    }, diff);
  };

  @action
  addBooking = async (bookingData) => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const {
        data: { booking, cartId, ttl },
      } = await API.post(API_ROUTES.ADD_ORDER, {
        cartId: this.cartId,
        booking: {
          ...bookingData,
          resourceId: bookingData.id,
        },
      });
      this.scheduleCartExpiration(ttl);
      this.cartId = cartId;
      this.items.push(booking);
      localStorage.setItem(CART_ID, this.cartId);
      router.push(APP_ROUTES.SIGN_IN);
    } catch (e) {
      toast.error(get(e, 'errors[0].detail', 'Error!'));
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteBooking = async (cartId, bookingId) => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      await API.delete(API_ROUTES.DELETE_ORDER(bookingId), {
        params: { cartId },
      });
      this.items = this.items.filter(item => item._id !== bookingId);
    } catch (e) {
      toast.error(e.error || e.message || "Couldn't delete booking.");
    } finally {
      this.isLoading = false;
    }
  };

  @action processOrder = async ({ fullPayment, ...formData }, createToken) => {
    if (this.isLoading) {
      return;
    }
    const hasStripeFields = STRIPE_FIELDS.every(prop => prop in formData);
    let paymentPayload = {};

    this.isLoading = true;
    try {
      if (hasStripeFields) {
        const { token } = await createToken();
        const stripeToken = get(token, 'id');
        paymentPayload = {
          stripeToken,
          billingAddress: {
            ...omit(formData, STRIPE_FIELDS),
          },
        };
      }
      await API.post(API_ROUTES.ORDERS('submit'), {
        ...paymentPayload,
        fullPayment: fullPayment === 'true',
        cartId: this.cartId,
        discountCode: formData.discountCode,
      });
      toast.success('Success!');
      this.isPaid = true;
      router.push(APP_ROUTES.BOOKINGS_SUCCESS);
      clearTimeout(cartExpirationTimeout);
    } catch (e) {
      toast.error(e.message || e.error);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  clearCart = () => {
    this.cartId = null;
    this.items = [];
    this.isPaid = false;
    localStorage.removeItem(CART_ID);
  };

  @computed
  get count() {
    return this.items.length;
  }

  @computed
  get formattedPrice() {
    let currency = '£';
    if (!isEmpty(this.items)) {
      currency = getSymbolFromCurrency(get(this.items, '[0].priceData.currency', 'GBP'));
    }
    const amount = this.items.reduce((sum, booking) => sum + get(booking, 'priceData.netAmountToPay', 0), 0);
    return {
      amount,
      currency,
    };
  }

  @computed
  get formattedGrossPrice() {
    let currency = '£';
    if (!isEmpty(this.items)) {
      currency = getSymbolFromCurrency(get(this.items, '[0].priceData.currency', 'GBP'));
    }
    let amount = this.items.reduce((sum, booking) => sum + get(booking, 'priceData.amountToPay', 0), 0);
    const { discount, discountUnit } = this.discountCode.data;

    if (discount && discountUnit) {
      amount = discountUnit === '%' ? amount * (1 - discount / 100) : amount - discount;
    }
    amount = amount < 0 ? 0 : amount;

    return {
      amount,
      currency,
    };
  }

  @computed
  get isAnyBookingIn48hrs() {
    const date = moment.tz(TZ).add({ hours: 48 });
    return !isEmpty(this.items.filter(item => moment.tz(item.from, TZ).isBefore(date)));
  }

  @action checkDiscountCode = async ({ code }) => {
    this.discountCode = {
      ...this.discountCode,
      isLoading: true,
    };
    try {
      const {
        data: { discountCode },
      } = await API.get(API_ROUTES.DISCOUNT_VALIDITY_CHECK(code));
      this.discountCode = {
        isLoading: false,
        message: `Your code is valid. ${discountCode.discountUnit !== '%' ? discountCode.discountUnit : ''}${discountCode.discount}${discountCode.discountUnit === '%' ? '%' : ''} discount will be applied to your order.`,
        data: discountCode,
      };
    } catch (e) {
      this.discountCode = {
        isLoading: false,
        message: 'Sorry! The code you entered is invalid or has expired.',
        data: {},
      };
    }
  };

  @action clearDiscountCodeState = () => {
    this.discountCode = { isLoading: false, message: '', data: {} };
  };
}

export default new CartStore();
