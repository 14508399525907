import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Button, { BUTTON_VARIANT } from '../../_common/components/Button';
import Location from '../../_common/components/Location';
import { FONT_FAMILY } from '../../theme';
import { getSymbolFromCurrency } from '../../utils/currency';
import { convertMetersToMiles } from '../../utils/distance';
import { Photo as ImagePhoto } from '../../_common/components/BookingListItem';

const Photo = styled(ImagePhoto)`
  height: 200px;
`;

const Wrapper = styled(Flex)`
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.mainOrange};
  position: relative;
  flex-direction: column;
  height: 100%;
`;

const CapacityText = styled(Text)`
  white-space: nowrap;
`;

const Badge = styled(Box)`
  &:before {
    content: '\\A';
    width: 10px;
    height: 10px;
    border-radius:50%;
    background: ${props => props.theme.colors.primaryText};
    position: absolute;
    top: 15px;
  }
  z-index: 1;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  top: -55px;
  left: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BadgeText = styled(Text)`
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  letter-spacing: -1px;
`;

const ResourceBoxItem = ({
  name,
  pricePerHour,
  photo,
  redirectPath,
  district,
  address,
  distance,
  openResourceDetails,
  additionalInfo,
}) => {
  const badge = get(additionalInfo, 'badge');
  const capacity = get(additionalInfo, 'capacity');
  return (
    <Flex flexDirection="column" width={[1, 1 / 2, 1 / 2, 1 / 3]} px={15} mb={60}>
      <Wrapper>
        {badge && (
          <Badge bg="secondaryOrange">
            <BadgeText fontFamily={FONT_FAMILY.HAILSR_BOLD}>
              {badge}
            </BadgeText>
          </Badge>
        )}
        <Photo bgSrc={get(photo, 'default.url')} />
        <Flex style={{ flex: 1 }} flexDirection="column" p={20}>
          <Flex justifyContent="space-between" alignItems="center" mb={10}>
            <Text mr={10} lineHeight={1} fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={32}>{name}</Text>
            <CapacityText fontWeight={500}>{`${capacity} ${capacity === 1 ? 'PERSON' : 'PEOPLE'}`}</CapacityText>
          </Flex>
          <Location mb={10} fontSize={16} fontFamily={FONT_FAMILY.HAILSR_BOLD}>{district}</Location>
          <Text color="mainOrange" mb={10} fontSize={14}>{address}</Text>
          {distance !== undefined &&
          <Text fontSize={13} mb={10}>{`${convertMetersToMiles(distance)} miles away`}</Text>}
          <Flex justifyContent="space-between" alignItems="flex-end" mt="auto">
            <Flex flexDirection="column">
              <Text fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={39} lineHeight={1}>
                {`${getSymbolFromCurrency(pricePerHour.currency)}${Number(pricePerHour.net).toFixed(2)}`}
              </Text>
              <Text fontFamily={FONT_FAMILY.HAILSR_BOLD} fontSize={12} line>+ VAT PER HOUR</Text>
            </Flex>
            <Flex flexDirection="column">
              <Link to={redirectPath}>
                <Button py={10} minWidth={141} variant={BUTTON_VARIANT.SECONDARY} mb={10}>
                  Quick book
                </Button>
              </Link>
              <Button py={10} minWidth={141} onClick={() => openResourceDetails()}>View</Button>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    </Flex>
  );
};

ResourceBoxItem.propTypes = {
  name: PropTypes.string.isRequired,
  district: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  distance: PropTypes.number,
  pricePerHour: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
  additionalInfo: PropTypes.object.isRequired,
  redirectPath: PropTypes.string.isRequired,
  openResourceDetails: PropTypes.func,
};

export default ResourceBoxItem;
