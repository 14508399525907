import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import BaseLayout from './BaseLayout';

const Wrapper = styled(Flex)`
  background-color: ${props => props.bgColor};
  justify-content: center;
  background: url(/images/carousel-item.png) no-repeat center center fixed;
  min-height: 100%;
  background-size: cover;
`;
const Overlay = styled(Box)`
  background-color: rgba(33, 40, 64, 0.65);
  width: 100%;
  min-height: 100%;
`;

const ImageLayout = ({ children, ...props }) => (
  <Wrapper bgImgSrc="/images/carousel-item.png">
    <Overlay>
      <BaseLayout {...props}>{children}</BaseLayout>
    </Overlay>
  </Wrapper>
);

ImageLayout.propTypes = {
  children: PropTypes.node,
};

export default ImageLayout;
