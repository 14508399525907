import validator from 'validator';
import get from 'lodash/get';
import moment from 'moment-timezone';

const { REACT_APP_TZ: TZ } = process.env;

export const composeValidators = (...validators) => (value, values) => validators.reduce(
  (error, _validator) => error || _validator(value, values),
  undefined,
);

export const required = value => (value ? undefined : 'This field is required');

export const mustBeValidStripeElement = value => get(value, 'error.message');

export const mustBePastCurrentDate = (value, allValues) => (parseFloat(value) <= parseFloat(allValues.from)
  ? 'A booking cannot end before it starts'
  : undefined);

export const mustBeLaterThanCurrentHour = (value, allValues) => (moment.tz(TZ).isAfter(moment.tz(`${allValues.date} ${allValues.from}`, TZ))
  ? 'Start time must be ahead'
  : undefined);

export const mustBeValidEmail = value => (validator.isEmail(value) ? undefined : 'Invalid email adress');

export const mustBe1hourDuration = (value, allValues) => {
  if (
    moment
      .duration(moment.tz(`${allValues.date} ${allValues.to}`, TZ).diff(moment.tz(`${allValues.date} ${allValues.from}`, TZ)))
      .asHours() < 1
  ) {
    return 'Minimum duration is 1h';
  }
  return undefined;
};

export const passwordsMustMatch = (value, allValues) => (value === allValues.password ? undefined : 'Password confirmation must match');

export const passwordLength = length => (value = '') => (value === '' || value.length >= length ? undefined : `Password needs to be ${length} characters or longer`);
