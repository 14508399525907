import axios from 'axios';
import authStore from '../stores/authStore';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const AUTH_TOKEN = localStorage.getItem('external-room-token');
if (AUTH_TOKEN) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

axiosInstance.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401) {
      authStore.logout();
    }
    return Promise.reject(error.response.data);
  },
);

export const setAuthenticationToken = (token) => {
  localStorage.setItem('external-room-token', token);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default axiosInstance;
