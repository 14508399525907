import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import SignUpWizard from '../../signUp/components/forms/SignUpWizard';
import Card from '../../_common/components/Card';
import CardTitle from './CardTitle';

const SignUpCard = ({ onSubmit, keepGuestData, validateUser }) => (
  <Flex width={[1, 1, 0.5]} pl={[0, 0, 10]} mb={20}>
    <Card width={1} bgColor="#ffda8e">
      <CardTitle>new user?</CardTitle>
      <SignUpWizard
        onSubmit={onSubmit}
        keepGuestData={keepGuestData}
        validateUser={validateUser}
      />
    </Card>
  </Flex>
);

SignUpCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  keepGuestData: PropTypes.func,
  validateUser: PropTypes.func.isRequired,
};

export default SignUpCard;
