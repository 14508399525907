import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { injectStripe } from 'react-stripe-elements';
import Card from '../_common/components/Card';
import Loader from '../_common/components/Loader';
import VerticalDottedLine from '../_common/components/VerticalDottedLine';
import CardTitle from '../signIn/components/CardTitle';
import { APP_ROUTES } from '../_app/routes';
import BookingListItem from '../_common/components/BookingListItem';
import PaymentForm from './components/forms/PaymentForm';
import Button, { BUTTON_VARIANT } from '../_common/components/Button';
import StyledAnchor from '../_common/components/StyledAnchor';
import withStripeWrapper from '../hoc/withStripeWrapper';

const Wrapper = styled(Flex)`
  max-width: 1117px;
`;

const VerticalLine = styled(VerticalDottedLine)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

@withRouter
@inject('cartStore', 'userStore')
@withStripeWrapper
@injectStripe
@observer
class BookingPayment extends Component {
  componentDidMount() {
    this.props.userStore.fetchProfile();
    this.props.cartStore.clearDiscountCodeState();
  }

  onSubmit = async (values) => {
    const {
      cartStore: { processOrder },
      stripe: { createToken },
    } = this.props;
    await processOrder(values, createToken);
  };
  render() {
    const {
      items,
      formattedGrossPrice,
      isLoading,
      deleteBooking,
      isAnyBookingIn48hrs,
      checkDiscountCode,
      discountCode,
    } = this.props.cartStore;
    const {
      profile,
      isLoading: isProfileLoading,
      fetchGuestData,
    } = this.props.userStore;
    const { isAuthenticated } = this.props;

    if (!isLoading && isEmpty(items)) {
      return <Redirect to={APP_ROUTES.ROOT} />;
    }
    return (
      <Wrapper width={1} flexDirection="column" my={60} mx="auto">
        <Card>
          <Flex width={1} flexWrap="wrap">
            <Flex
              width={[1, 1, 0.5]}
              flexDirection="column"
              mb={[20, 20, 0]}
              order={[2, 2, 1]}
            >
              <CardTitle>How to pay</CardTitle>
              <Flex width={1} mt={20}>
                {isProfileLoading && (
                  <Box width={1}>
                    <Loader fullPage={false} />
                  </Box>
                )}
                {!isProfileLoading && (
                  <PaymentForm
                    fetchGuestData={fetchGuestData}
                    profile={profile}
                    isAuthenticated={isAuthenticated}
                    onSubmit={this.onSubmit}
                    price={formattedGrossPrice}
                    depositDisabled={isAnyBookingIn48hrs}
                    checkDiscountCode={checkDiscountCode}
                    discountCode={discountCode}
                  />
                )}
                <VerticalLine mx={30} />
              </Flex>
            </Flex>
            <Box width={[1, 1, 0.5]} order={[1, 1, 2]}>
              <Text fontSize={18} mb={32}>
                {"Here's what you're booking:"}
              </Text>
              {items.map(({ space, resource, ...item }, i) => (
                <BookingListItem
                  {...item}
                  {...space}
                  {...resource}
                  key={`payment-booking-list-item-${i}`}
                  onDelete={deleteBooking}
                />
              ))}
              <Link to={APP_ROUTES.ROOT}>
                <Button py={10} variant={BUTTON_VARIANT.SECONDARY}>
                  Add another booking
                </Button>
              </Link>

              <Text mt={18} fontSize={13} mb={[20, 20, 0]}>
                View our
                {' '}
                <StyledAnchor
                  fontSize="13px"
                  target="_blank"
                  href="https://work.life/memberterms/meetingrooms"
                >
                  Cancellation policy
                </StyledAnchor>
                {' '}
                information
              </Text>
            </Box>
          </Flex>
        </Card>
      </Wrapper>
    );
  }
}

BookingPayment.wrappedComponent.propTypes = {
  cartStore: PropTypes.shape({
    items: PropTypes.array.isRequired,
    formattedGrossPrice: PropTypes.object,
    deleteBooking: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    processOrder: PropTypes.func,
    isAnyBookingIn48hrs: PropTypes.bool.isRequired,
    checkDiscountCode: PropTypes.func,
    discountCode: PropTypes.object,
    clearDiscountCodeState: PropTypes.func,
  }),
  userStore: PropTypes.shape({
    fetchProfile: PropTypes.func.isRequired,
    fetchGuestData: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }),
  stripe: PropTypes.object,
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default BookingPayment;
