import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SliderRaw from 'react-slick';
import { Image } from 'rebass';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Caret = ({ src, onClick, position }) => (
  <Image
    src={src}
    onClick={onClick}
    style={{
      position: 'absolute',
      top: 'calc(50% - 16px)',
      zIndex: 1,
      cursor: 'pointer',
      ...position,
    }}
  />
);

Caret.propTypes = {
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  position: PropTypes.object.isRequired,
};

class Slider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <Caret src="/images/icons/caret-left.png" position={{ left: 10 }} />,
      nextArrow: <Caret src="/images/icons/caret-right.png" position={{ right: 10 }} />,
    };

    return (
      <SliderRaw {...settings}>
        {this.props.children}
      </SliderRaw>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Slider;
