import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../../../_app/routes';
import SignInModal from '../../../signIn/components/SignInModal';
import Button from '../Button';
import Cart from './Cart';
import Menu from './Menu';
import SubHeader from './SubHeader';

const Wrapper = styled.header`
  width: 100%;
  padding: 15px;
  padding-bottom: ${props => props.pb}px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #212840;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 25;
  height: 67px;
`;

const ROUTES_WITHOUT_LOGIN_BTN = [APP_ROUTES.SIGN_IN, APP_ROUTES.RESET_PASSWORD];

@withRouter
@inject('authStore')
@observer
class NavBar extends Component {
  render() {
    const {
      titles,
      match: { path },
      authStore: {
        isAuthenticated,
        loginModalOpened,
        toggleLoginModal,
        signIn,
        onForgotPassword,
        logout,
      },
    } = this.props;
    return (
      <>
        <Wrapper pb={isEmpty(titles ? 30 : 15)}>
          <Link to={APP_ROUTES.ROOT}>
            <Image ml={[0, 40]} src="/images/logo@2x.png" width={[160, 200]} />
          </Link>
          <Flex alignItems="center">
            <Cart />
            {isAuthenticated && <Menu logout={logout} />}
            {!isAuthenticated && !includes(ROUTES_WITHOUT_LOGIN_BTN, path) && (
              <Button
                ml={[0, 20, 20]}
                mr={[0, 40, 40]}
                py="8px"
                width={[100, 160, 160]}
                minWidth={100}
                onClick={toggleLoginModal}
              >
                Log in
              </Button>
            )}
          </Flex>
        </Wrapper>
        {!isEmpty(titles) && (
          <SubHeader titles={titles} />
        )}
        <SignInModal
          isOpen={loginModalOpened}
          onClose={toggleLoginModal}
          actions={{ signIn, onForgotPassword }}
        />
      </>
    );
  }
}
NavBar.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    toggleLoginModal: PropTypes.func.isRequired,
    loginModalOpened: PropTypes.bool.isRequired,
    signIn: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func,
  }).isRequired,
  titles: PropTypes.array,
  match: PropTypes.object,
};

NavBar.wrappedComponent.defaultProps = {
  titles: [],
};

export default NavBar;
