import { Flex } from 'rebass';
import styled from 'styled-components';

const HorizontalLine = styled(Flex)`
  width: 100%;
  height: 0;
  border-bottom: 5px ${props => props.theme.colors.mainOrange} dotted;
`;

export default HorizontalLine;
