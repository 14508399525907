import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Text } from 'rebass';
import ProfileForm from './components/forms/ProfileForm';

@inject('userStore')
@observer
class AccountPage extends Component {
  componentDidMount() {
    this.props.userStore.fetchProfile();
  }

  render() {
    const { profile, isLoading, updateProfile } = this.props.userStore;
    return (
      <Box width={[1, 1, 0.7]}>
        <Text
          fontSize={51}
          color="secondaryText"
          fontFamily="VeneerClean"
          mb={25}
          mt={70}
        >
          Personal details
        </Text>
        <ProfileForm
          initialValues={profile}
          onSubmit={updateProfile}
          isLoading={isLoading}
        />
      </Box>
    );
  }
}

AccountPage.wrappedComponent.propTypes = {
  userStore: PropTypes.shape({
    fetchProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AccountPage;
