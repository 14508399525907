import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as RawButton, Image } from 'rebass';
import { FONT_FAMILY } from '../../theme';

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const StyledButton = styled(RawButton)`
  text-shadow: none;
  background: #fff;
  color: #000;
  border: 2px solid ${({ variant, theme }) => (variant === BUTTON_VARIANT.SECONDARY ? theme.colors.buttonSecondary : '#f8b978')};
  border-radius: 0px;
  border-bottom-right-radius: 18px;
  text-transform: uppercase;
  ${props => `font-size: ${props.responsiveFont ? 'calc(12px + 0.4vw)' : '14px'};`}
  text-align: center;
  font-family: ${props => props.theme.fonts[FONT_FAMILY.HAILSR_BOLD]};
  cursor: pointer;
  font-weight: 400;
  min-width: ${props => props.minWidth || 160}px;
  transition: all 0.5s cubic-bezier(0.25, 0.25, 0, 1);
  box-shadow: 3px 4px 0 0
    ${({ variant, theme }) => (variant === BUTTON_VARIANT.SECONDARY ? theme.colors.buttonSecondary : '#f8b978')};
  vertical-align: baseline;

  &:hover {
    transform: translateX(2px) translateY(2px);
    box-shadow: 1px 2px 0 0
      ${({ variant, theme }) => (variant === BUTTON_VARIANT.SECONDARY ? theme.colors.buttonSecondary : '#f8b978')};
  }
  ${props => props.fullwidth && 'width: 100%;'}
`;

const Button = ({ children, loading, ...props }) => (
  <StyledButton {...props} style={{ position: 'relative' }}>
    {loading ? <Image width={27} height="auto" src="/images/icons/loader.gif" /> : children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([BUTTON_VARIANT.PRIMARY, BUTTON_VARIANT.SECONDARY]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};

Button.defaultProps = {
  px: 20,
  py: 13,
  loading: false,
  variant: BUTTON_VARIANT.PRIMARY,
};

export default Button;
