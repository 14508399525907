import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  display: inline-block;
  color: #000;
  background-color: transparent;
  :hover {
    color: ${props => props.theme.colors.secondaryText};
  }
`;

const UnderlineButton = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

UnderlineButton.propTypes = {
  children: PropTypes.node,
  fontSize: PropTypes.number,
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UnderlineButton.defaultProps = {
  fontSize: 13,
  px: 0,
  py: '5px',
};

export default UnderlineButton;
