import get from 'lodash/get';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Button, { BUTTON_VARIANT } from '../../../_common/components/Button';
import FormRadio from '../../../_common/components/forms/FormRadio';
import RadioGroup from '../../../_common/components/forms/RadioGroup';
import { FONT_FAMILY } from '../../../theme';
import { getSymbolFromCurrency } from '../../../utils/currency';
import { required } from '../../../utils/formValidators';
import PaymentDetailsFields from './PaymentDetailsFields';
import TextInput from '../../../_common/components/forms/TextInput';

const RadioWrapper = styled(Box).attrs({
  p: 20,
  alignItems: 'center',
})`
  border: 1px solid ${props => props.theme.colors.secondaryText};
`;

const PaymentForm = ({
  onSubmit,
  isAuthenticated,
  price: { amount, currency },
  profile,
  depositDisabled,
  fetchGuestData,
  checkDiscountCode,
  discountCode,
}) => {
  const [isNewCard, setIsNewCard] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const creditCard = get(profile, 'creditCard');
  const guest = fetchGuestData();

  const formData = { ...guest, ...profile };
  const initialName = formData.firstName
    ? { name: `${formData.firstName} ${formData.lastName}` }
    : {};
  const initialValues = {
    ...pick(formData, ['phone', 'email', 'company']),
    ...initialName,
    companyName: get(formData, 'company'),
    fullPayment: 'true',
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <RadioGroup name="fullPayment">
            <RadioWrapper mb={11}>
              <Flex>
                <Field
                  id="full"
                  name="fullPayment"
                  component={FormRadio}
                  type="radio"
                  value="true"
                  validate={required}
                  label={<Text fontSize={17}>Pay in full</Text>}
                />
                <Text ml="auto">
                  {`${getSymbolFromCurrency(currency)}${amount.toFixed(2)}`}
                </Text>
              </Flex>
            </RadioWrapper>
            <RadioWrapper>
              <Flex>
                <Box>
                  <Field
                    id="due"
                    name="fullPayment"
                    component={FormRadio}
                    type="radio"
                    value="false"
                    validate={required}
                    disabled={depositDisabled}
                    label={<Text fontSize={17}>Deposit (due now)</Text>}
                  />
                  <Text
                    ml={30}
                    fontFamily={FONT_FAMILY.HAILSR_BOOK}
                    fontSize={14}
                  >
                    The remaining amounts will be charged 48 hours prior to
                    meeting room booking (no extra fees)
                  </Text>
                </Box>
                <Text ml="auto">
                  {`${getSymbolFromCurrency(currency)}${(amount * 0.2).toFixed(2)}`}
                </Text>
              </Flex>
            </RadioWrapper>
          </RadioGroup>
          {isAuthenticated && (
            <>
              {!isNewCard && creditCard && (
                <>
                  <Text mt={20} fontSize={15}>
                    {`Pay with ${creditCard.brand} ending *${creditCard.last4}`}
                  </Text>
                  <Button
                    mt={20}
                    variant={BUTTON_VARIANT.SECONDARY}
                    onClick={() => setIsNewCard(true)}
                  >
                    Add new card
                  </Button>
                </>
              )}
              {(isNewCard || !creditCard) && <PaymentDetailsFields />}
            </>
          )}
          {!isAuthenticated && <PaymentDetailsFields />}

          {!showDiscount && (
            <Flex width={1} mt={30}>
              <Button type="button" onClick={() => setShowDiscount(true)}>
                Add discount code
              </Button>
            </Flex>
          )}
          {showDiscount && (
            <Box width={1} mt={30}>
              <Field name="discountCode" component={TextInput} type="text" label="Discount code" />
              <Text my={15}>{discountCode.message}</Text>
              <Button
                type="button"
                loading={discountCode.isLoading}
                disabled={discountCode.isLoading}
                onClick={() => checkDiscountCode({ code: values.discountCode })}
              >
                Check the code
              </Button>
            </Box>
          )}

          <Box width={1} mt={30}>
            <Button type="submit" disabled={submitting} loading={submitting}>
              Confirm and pay
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

PaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  price: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  depositDisabled: PropTypes.bool.isRequired,
  fetchGuestData: PropTypes.func.isRequired,
  checkDiscountCode: PropTypes.func,
  discountCode: PropTypes.object,
};

export default PaymentForm;
