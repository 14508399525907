import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Flex } from 'rebass';
import { FONT_FAMILY } from '../../theme';
import SignInForm from './forms/SignInForm';
import Card from '../../_common/components/Card';
import CardTitle from './CardTitle';
import StyledAnchor from '../../_common/components/StyledAnchor';
import ForgotPasswordForm from './forms/ForgotPaswordForm';

const SignInCard = ({ actions: { signIn, onForgotPassword } }) => {
  const [showForgotPasswordForm, setForgotPasswordForm] = useState(false);
  return (
    <Flex width={[1, 1, 0.5]} pr={[0, 0, 10]} mb={20}>
      <Card width={1}>
        <CardTitle>welcome back</CardTitle>
        <Text
          fontSize={18}
          color="secondaryText"
          mt={10}
          mb={25}
        >
          {showForgotPasswordForm
            ? 'Forgot password?'
            : 'Sign in for speedy access:'}
        </Text>
        {!showForgotPasswordForm ? (
          <SignInForm onSubmit={signIn} toggleForm={setForgotPasswordForm} />
        ) : (
          <ForgotPasswordForm
            onSubmit={onForgotPassword}
            toggleForm={setForgotPasswordForm}
          />
        )}

        <Flex flexWrap="wrap" alignItems="center" mt={40}>
          <Text fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} mr="4px">By logging in you agree to our</Text>
          <StyledAnchor fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} href="https://work.life/memberterms/meetingrooms">
            Terms of Service
          </StyledAnchor>
          <Text fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} mx="4px">and</Text>
          <StyledAnchor fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} href="https://work.life/privacy/">Privacy Policy</StyledAnchor>
        </Flex>
      </Card>
    </Flex>
  );
};

SignInCard.propTypes = {
  actions: PropTypes.shape({
    signIn: PropTypes.func,
    onForgotPassword: PropTypes.func,
  }),
};

export default SignInCard;
