import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { Flex } from 'rebass';
import Button from '../../../_common/components/Button';
import DatePicker from '../../../_common/components/forms/DatePicker';
import FormSelect from '../../../_common/components/forms/FormSelect';
import {
  composeValidators,
  mustBe1hourDuration,
  mustBeLaterThanCurrentHour,
  required,
} from '../../../utils/formValidators';
import { BOOKING_HOURS } from '../../../utils/hoursHelpers';

const bookingHours = BOOKING_HOURS(9, 17);
const { REACT_APP_TZ: TZ } = process.env;

const BookingEditForm = ({ initialValues, onCancel, onSubmit }) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <Field
            name="date"
            label="DATE"
            component={DatePicker}
            disableDayFn={date => moment.tz(date, TZ).isBefore(moment.tz(TZ).format('YYYY-MM-DD'))}
            disableWeekends
          />
          <Field
            name="from"
            component={FormSelect}
            label="CHECK-IN"
            width={1}
            validate={composeValidators(required, mustBeLaterThanCurrentHour)}
            options={bookingHours}
          />
          <Field
            name="to"
            label="CHECK-OUT"
            width={1}
            component={FormSelect}
            validate={composeValidators(required, mustBe1hourDuration)}
            options={bookingHours}
          />
          <Flex mt={30}>
            <Button mr={20} disabled={submitting} loading={submitting}>
              Save
            </Button>
            <Button type="button" onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </form>
    )}
  />
);

BookingEditForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withRouter(BookingEditForm);
