import styled from 'styled-components';
import { Text } from 'rebass';
import { FONT_FAMILY } from '../../theme';

const Location = styled(Text)`
  margin-left: 20px;
  font-size: ${props => `${props.fontSize}px` || '18px'};
  position: relative;
  font-family: ${props => props.theme.fonts[FONT_FAMILY.HAILSR_BOLD]};
  :before {
    content: '';
    background-image: url(/images/icons/location-icon@2x.png);
    width: 15px;
    height: 23px;
    background-size: cover;
    background-position: center;
    left: -20px;
    position: absolute;
  }
`;

export default Location;
