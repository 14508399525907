import isEqual from 'lodash/isEqual';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import { APP_ROUTES } from '../_app/routes';
import Button from '../_common/components/Button';
import Loader from '../_common/components/Loader';
import StyledAnchor from '../_common/components/StyledAnchor';
import ResourceBoxItem from './components/ResourceBoxItem';
import ResourceDetailsModal from './components/ResourceDetailsModal';
import SearchResultsForm from './forms/SearchResultsForm';
import { CovidLink } from '../search/index';

@withRouter
@inject('searchStore')
@observer
class SearchPage extends Component {
  @observable isModalOpened = false;

  componentDidMount() {
    this.fetchResources();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.fetchResources();
    }
  }
  fetchResources = () => {
    const {
      searchStore: { fetchResources },
      location: { search },
    } = this.props;
    fetchResources(queryString.parse(search));
  };
  toggleModal = () => {
    this.isModalOpened = !this.isModalOpened;
  };
  openResourceDetailsModal = (item) => {
    this.props.searchStore.setCurrentResource(item);
    this.toggleModal();
  };

  render() {
    const {
      searchStore: {
        isLoading,
        paginatedResources,
        exactResources,
        exactLocation,
        hasMore,
        fetchMoreResources,
        resourcesCount,
        currentResource,
      },
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    console.log('Query: ', query);
    const showExact = query.distanceSort !== 'true';
    console.log('Show exact', showExact);
    return (
      <>
        <Text pt={60} fontSize="23px" textAlign="center">
          Please read our&nbsp;
          <CovidLink href="https://work.life/covid-19-policy/" target="_blank" rel="noopener noreferrer" fontSize="23px">COVID-19 policy</CovidLink>
        </Text>
        <Flex flexDirection="column" alignItems="center" pt={60}>
          <SearchResultsForm initialValues={query} />
          {isLoading && <Loader />}
          {resourcesCount === 0 && (!exactResources || exactResources.length === 0) && !isLoading && !showExact && (
          <Text mt={20} fontSize={20}>
            No meeting rooms found! Please search for a different time or date, or contact us
          </Text>
          )}
          {showExact && (
            <>
              {!!exactResources.length && !isLoading && (
              <>
                <Text flex="1" alignSelf="flex-start" xcolor="mainOrange" fontSize={20} mt={30} mb={30} paddingLeft={15}>
                  Thanks for searching for meeting rooms in Work.Life
                  {' '}
                  {exactLocation}
                  ! Here are what we have available:
                </Text>
                <Flex flexWrap="wrap" width={1} mt={60} mx={-15}>
                  {exactResources.map((item, i) => (
                    <ResourceBoxItem
                      {...item}
                      key={`search-results-${i}`}
                      redirectPath={`${APP_ROUTES.RESOURCE_BOOKING_CONFIRMATION(
                        item.id,
                      )}${search}`}
                      openResourceDetails={() => this.openResourceDetailsModal(item)
                      }
                    />
                  ))}
                </Flex>
              </>
              )}
              {exactResources.length === 0 && !isLoading && (
              <Text mt={20} mb={20} ml={15} fontSize={20}>
                Oh no, sorry! We don’t have any rooms available in
                {' '}
                {exactLocation}
                {' '}
                at that time. Please see below what we have available in our other locations.
              </Text>
              )}
            </>
          )}
          {!!resourcesCount && (
            <>
              {showExact && (
                <Text flex="1" alignSelf="flex-start" xcolor="mainOrange" fontSize={20} mt={30} mb={30} paddingLeft={15}>
                  Meeting rooms in our other locations:
                </Text>
              )}
              <Flex flexWrap="wrap" width={1} mt={60} mx={-15}>
                {paginatedResources.map((item, i) => (
                  <ResourceBoxItem
                    {...item}
                    key={`search-results-${i}`}
                    redirectPath={`${APP_ROUTES.RESOURCE_BOOKING_CONFIRMATION(
                      item.id,
                    )}${search}`}
                    openResourceDetails={() => this.openResourceDetailsModal(item)
                    }
                  />
                ))}
              </Flex>
            </>
          )}
          {hasMore && (
          <Button mb={20} onClick={fetchMoreResources}>Load more</Button>
          )}
          <Text fontSize={13} mb={60}>
            {'Can\'t see what you\'re looking for?'}
            {' '}
            <StyledAnchor fontSize={13} href="mailto:meetings@work.life">
            Get in touch
            </StyledAnchor>
            {' '}
            {'to see if we can help.'}
          </Text>

          <ResourceDetailsModal
            isOpen={this.isModalOpened}
            onClose={this.toggleModal}
            resource={currentResource}
            redirectPath={`${APP_ROUTES.RESOURCE_BOOKING_CONFIRMATION(
              currentResource.id,
            )}${search}`}
          />
        </Flex>
      </>
    );
  }
}

SearchPage.wrappedComponent.propTypes = {
  searchStore: PropTypes.shape({
    fetchResources: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    resourcesList: PropTypes.array.isRequired,
    paginatedResources: PropTypes.array,
    currentResource: PropTypes.object,
    setCurrentResource: PropTypes.func,
  }).isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default SearchPage;
