import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { Checkbox } from './FormCheckbox';

const FormRadio = ({
  label, input, id, meta, ...props
}) => (
  <Flex fontSize={13}>
    <Checkbox type="checkbox" {...props} {...input} id={id} />
    <label htmlFor={id} ml={15}>
      {label}
    </label>
  </Flex>
);

FormRadio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default FormRadio;
