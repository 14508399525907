import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import Script from 'react-load-script';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { withRouter } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILY } from '../../../theme';
import FormError from './FormError';
import Label from './FormLabel';
import { BaseFormInput } from './TextInput';

const PlacesWrapper = styled(Flex)`
  position: absolute;
  width: 337px;
  height: auto;
  top: 5px;
  left: 0;
  box-shadow: 4px 4px 0px 0px rgba(250, 190, 121, 1);
  border: 1px solid rgba(250, 190, 121, 1);
  flex-direction: column;
  background: white;
  z-index: 10;
`;

const PlaceText = styled(Text)`
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.mainOrange};
  }
`;

class GooglePlacesAutocomplete extends Component {
  state = {
    address: '',
    scriptLoaded: false,
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.scriptLoaded !== this.state.scriptLoaded) {
      const { search } = this.props.location;
      const params = queryString.parse(search);
      if (params.place) {
        this.handleSelect(params.place);
      } else {
        try {
          const geocode = await geocodeByPlaceId(this.props.placeId);
          this.setState({ address: geocode[0].formatted_address }); // eslint-disable-line
        } catch (e) {
          // TODO: handle error
        }
      }
    }
  }

  onFormInputChange = () => {};

  handleChange = (address) => {
    this.setState({ address }, () => {
      this.onFormInputChange(address);
    });
  };

  handleSelect = async (address) => {
    try {
      const { formChange } = this.props;

      const geocode = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(geocode[0]);
      const placeId = geocode[0].place_id;
      this.setState({ address }, () => {
        this.onFormInputChange(address);
        formChange('location', [lat, lng]);
        formChange('placeId', placeId);
      });
    } catch (e) {
      // TODO: handle error
      console.error('[GOOGLE API ERROR]', e);
    }
  };

  _renderPlaces() {
    const {
      name, placeholder, validate, label,
    } = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={650}
        highlightFirstSuggestion
        searchOptions={{
          types: ['geocode'],
          componentRestrictions: {
            country: ['gb'],
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Flex flexDirection="column">
            <Field name={name} component="input" validate={validate}>
              {({ input, meta }) => {
                this.onFormInputChange = input.onChange;
                return (
                  <Box>
                    {label && <Label>{label}</Label>}
                    <BaseFormInput
                      {...input}
                      {...getInputProps({ placeholder })}
                    />
                    <FormError meta={meta} />
                  </Box>
                );
              }}
            </Field>
            <Box style={{ position: 'relative' }}>
              {!isEmpty(suggestions) && (
                <PlacesWrapper pt={10} px={10}>
                  {suggestions.map(suggestion => (
                    <Box
                      {...getSuggestionItemProps(suggestion, {
                        active: suggestion.active,
                      })}
                    >
                      <PlaceText fontFamily={FONT_FAMILY.HAILSR_BOOK}>{suggestion.description}</PlaceText>
                    </Box>
                  ))}
                </PlacesWrapper>
              )}
            </Box>
          </Flex>
        )}
      </PlacesAutocomplete>
    );
  }

  render = () => (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${
          process.env.REACT_APP_GOOGLE_API_KEY
        }&libraries=places`}
        onLoad={() => this.setState({ scriptLoaded: true })}
      />
      {this.state.scriptLoaded && this._renderPlaces()}
    </>
  );
}

GooglePlacesAutocomplete.defaultProps = {
  validate: undefined,
  placeholder: undefined,
  initialLocation: '',
};

GooglePlacesAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  initialLocation: PropTypes.any,
  formChange: PropTypes.func.isRequired,
  placeId: PropTypes.string,
  label: PropTypes.string,
  location: PropTypes.object.isRequired,
};

export default withRouter(GooglePlacesAutocomplete);
