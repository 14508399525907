import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../_app/routes';

const redirectAuthenticatedUser = (ComposedComponent) => {
  @inject('authStore')
  @observer
  class Authenticate extends Component {
    static propTypes = {
      authStore: PropTypes.shape({
        isAuthenticated: PropTypes.bool.isRequired,
      }).isRequired,
    };

    render() {
      const { isAuthenticated } = this.props.authStore;
      if (!isAuthenticated) {
        return <ComposedComponent {...this.props} />;
      }
      return <Redirect to={APP_ROUTES.BOOKING_PAYMENT} />;
    }
  }

  return Authenticate;
};

export default redirectAuthenticatedUser;
