import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';
import moment from 'moment-timezone';
import get from 'lodash/get';
import styled from 'styled-components';
import { FONT_FAMILY } from '../../theme';
import HrLine from './HrLine';
import UnderlineButton from './UnderlineButton';

const { REACT_APP_TZ: TZ } = process.env;

export const Photo = styled(Box)`
  background-image: url(${props => props.bgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 150px;
  clip-path: inset(-35% -15% 0% -15% round 100%);
`;

const BookingListItem = ({
  from,
  to,
  photo,
  name,
  district,
  onDelete,
  additionalInfo,
  _id: id,
  _orderId: orderId,
}) => {
  const capacity = get(additionalInfo, 'capacity', 0);
  return (
    <Flex width={1} mb={[15, 30]} flexWrap="wrap">
      <Flex width={[1, 0.5]} flexDirection="column" pr={10} order={[2, 1]}>
        <HrLine color="#fabe79" order={[2, 0]} mt={[15, 0]} />
        <Text fontSize={23} mb={10} fontFamily="VeneerClean" mt={16} lineHeight={1}>
          {name}
        </Text>
        <Text fontSize={15}>
          <Text>{district}</Text>
          <Text fontFamily={FONT_FAMILY.HAILSR_BOOK}>{moment.tz(from, TZ).format('dddd Do MMMM')}</Text>
          <Text fontFamily={FONT_FAMILY.HAILSR_BOOK}>
            {`${moment.tz(from, TZ).format('HH:mm')} - ${moment.tz(to, TZ).format('HH:mm')}`}
          </Text>
          <Text fontFamily={FONT_FAMILY.HAILSR_BOOK}>{`${capacity} ${capacity > 1 ? 'People' : 'Person'}`}</Text>
          {onDelete && (
            <UnderlineButton mt={10} onClick={() => onDelete(orderId, id)}>
              Remove
            </UnderlineButton>
          )}
        </Text>
      </Flex>
      <Box width={[1, 0.5]} order={[1, 2]}>
        <Photo bgSrc={get(photo, 'default.url')} />
      </Box>
    </Flex>
  );
};

BookingListItem.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.object,
  additionalInfo: PropTypes.object,
  district: PropTypes.string,
  _id: PropTypes.string,
  _orderId: PropTypes.string,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

export default BookingListItem;
