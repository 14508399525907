import get from 'lodash/get';
import noop from 'lodash/noop';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Button from '../../_common/components/Button';
import ConfirmationModal from '../../_common/components/ConfirmationModal';
import Location from '../../_common/components/Location';
import BookingEditModal from '../../bookingEdit/components/BookingEditModal';
import EditPaymentModal from './EditPaymentModal';

const { REACT_APP_API_URL, REACT_APP_TZ: TZ } = process.env;
const Wrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.mainOrange};
`;

const BoxWithBorder = styled(Flex)`
  border-bottom: 1px solid ${props => props.theme.colors.mainOrange};
  padding-bottom: 20px;
`;

const Photo = styled(Box)`
  background-size: cover;
  background-position: center;
  background-image: ${props => `url(${props.src})`};
  min-height: 200px;
  height: 100%;
  width: 100%;
`;

const BookingListItem = ({
  from,
  to,
  _id: id,
  invoiceUrl,
  cancelled,
  resource = {},
  space = {},
  deleteBooking,
  editPaymentMethod,
  created_at: createdAt,
  updateBooking,
}) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const [isEditPaymentOpened, setIsEditPaymentOpened] = React.useState(false);
  const [isEditBookingModalOpened, setIsEditBookingModalOpened] = React.useState(false);
  const capacity = get(resource, 'additionalInfo.capacity');
  const canBookingBeEdited = () => {
    const now = moment.tz(TZ);
    const momentFrom = moment.tz(from, TZ);
    const momentCreatedAt = moment.tz(createdAt, TZ);
    return momentFrom.diff(now, 'hour') >= 48 && momentCreatedAt.diff(now, 'hour') <= 24;
  };
  return (
    <>
      <Wrapper width={1} mb={40} flexWrap={['wrap', 'nowrap']}>
        <Flex
          width={[1, 0.7]}
          flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
          order={[2, 1]}
        >
          <Flex flexDirection="column" mx={20} width={1} py={20}>
            <Flex mb={20} width={1}>
              <Flex flexDirection="column" mr={20} width={[0.5, 180]}>
                <Text fontSize={13}>DATE</Text>
                <Text fontSize={21}>{moment.tz(from, TZ).format('DD-MM-YYYY')}</Text>
              </Flex>
              <Flex flexDirection="column" mr={20}>
                <Text fontSize={13}>CHECK-IN</Text>
                <Text fontSize={21}>{moment.tz(from, TZ).format('HH:mm')}</Text>
              </Flex>
              <Flex flexDirection="column" mr={20}>
                <Text fontSize={13}>CHECK-OUT</Text>
                <Text fontSize={21}>{moment.tz(to, TZ).format('HH:mm')}</Text>
              </Flex>
            </Flex>
            <BoxWithBorder alignItems="center" mb={10} width={1}>
              <Text width={[0.5, 180]} mr={20}>
                {resource.name}
              </Text>
              {capacity && <Text>{`${capacity} ${capacity === 1 ? 'PERSON' : 'PEOPLE'}`}</Text>}
            </BoxWithBorder>
            <Flex alignItems="center" width={1} flexWrap="wrap">
              <Location width={[0.5, 180]} fontSize={16} mt={10}>
                {space.district}
              </Location>
              <Text color="mainOrange" fontSize={14} mt={10}>
                {space.address}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column" p={20}>
            {!cancelled && (
              <>
                {invoiceUrl ? (
                  <a
                    target="_blank"
                    href={`${REACT_APP_API_URL}${invoiceUrl}`}
                    rel="noopener noreferrer"
                  >
                    <Button minWidth={220}>View invoice</Button>
                  </a>
                ) : (
                  <>
                    {canBookingBeEdited() && (
                      <Button width={1} mb={10} onClick={() => setIsEditBookingModalOpened(true)}>
                        Edit
                      </Button>
                    )}
                    <BookingEditModal
                      isOpen={isEditBookingModalOpened}
                      onCancel={() => setIsEditBookingModalOpened(false)}
                      onSubmit={async (values) => {
                        const [fromHour, fromMinute] = values.from.split(':');
                        const [toHour, toMinute] = values.to.split(':');
                        const updatedFrom = moment.tz(values.date, TZ).hour(fromHour).minute(fromMinute);
                        const updatedTo = moment.tz(values.date, TZ).hour(toHour).minute(toMinute);
                        await updateBooking(id, updatedFrom, updatedTo);
                      }}
                      initialValues={{
                        date: from,
                        from: moment.tz(from, TZ).format('HH:mm'),
                        to: moment.tz(to, TZ).format('HH:mm'),
                      }}
                    />
                    <Button mb={10} minWidth={220} onClick={() => setIsOpened(true)}>
                      Cancel
                    </Button>
                    <ConfirmationModal
                      isOpen={isOpened}
                      text="Are you sure?"
                      onCancel={() => setIsOpened(false)}
                      onConfirm={async () => {
                        await deleteBooking(id);
                        setIsOpened(false);
                      }}
                    />
                    <Button minWidth={230} onClick={() => setIsEditPaymentOpened(true)}>
                      Edit payment method
                    </Button>
                    <EditPaymentModal
                      isOpen={isEditPaymentOpened}
                      onCancel={() => setIsEditPaymentOpened(false)}
                      onSubmit={createToken => editPaymentMethod(id, createToken, setIsEditPaymentOpened)}
                      bookingId={id}
                    />
                  </>
                )}
              </>
            )}
          </Flex>
        </Flex>
        <Box width={[1, 0.3]} order={[1, 2]}>
          <Photo src={get(resource, 'photo.default.url')} />
        </Box>
      </Wrapper>
    </>
  );
};

BookingListItem.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  invoiceUrl: PropTypes.string,
  _id: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  space: PropTypes.object.isRequired,
  deleteBooking: PropTypes.func,
  cancelled: PropTypes.any,
  editPaymentMethod: PropTypes.func,
  updateBooking: PropTypes.func,
  created_at: PropTypes.string,
};

BookingListItem.defaultProps = {
  deleteBooking: noop,
};

export default BookingListItem;
