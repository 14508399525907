import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Flex } from 'rebass';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import get from 'lodash/get';
import StripeInput from '../../../_common/components/forms/StripeInput';
import Button from '../../../_common/components/Button';
import withStripeWrapper from '../../../hoc/withStripeWrapper';
import {
  required,
  composeValidators,
  mustBeValidStripeElement,
} from '../../../utils/formValidators';

const EditPaymentForm = ({ onSubmit, onCancel, stripe }) => (
  <Form
    onSubmit={() => onSubmit(get(stripe, 'createToken', () => {}))}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex width={1} flexDirection="column">
          <Field
            name="cardNumber"
            validate={composeValidators(required, mustBeValidStripeElement)}
          >
            {({ input, meta }) => (
              <StripeInput
                label="Card number"
                component={CardNumberElement}
                input={input}
                meta={meta}
                onChange={input.onChange}
              />
            )}
          </Field>
          <Field
            name="cardCvc"
            validate={composeValidators(required, mustBeValidStripeElement)}
          >
            {({ input, meta }) => (
              <StripeInput
                label="Security code"
                component={CardCVCElement}
                input={input}
                meta={meta}
                onChange={input.onChange}
              />
            )}
          </Field>
          <Field
            name="cardExpiry"
            validate={composeValidators(required, mustBeValidStripeElement)}
          >
            {({ input, meta }) => (
              <StripeInput
                label="Expires on"
                placeholder="MM / YY"
                component={CardExpiryElement}
                input={input}
                meta={meta}
                onChange={input.onChange}
              />
            )}
          </Field>
          <Flex mt={30}>
            <Button mr={20} disabled={submitting} loading={submitting}>
              Save
            </Button>
            <Button type="button" onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </form>
    )}
  />
);

EditPaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stripe: PropTypes.object,
};

export default withStripeWrapper(injectStripe(EditPaymentForm));
