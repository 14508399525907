import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background-color: ${props => props.bgColor};
  justify-content: center;
  width: 100%;
  min-height: 100%;
`;

const BaseLayout = styled(Box)`
  max-width: ${props => props.maxWidth};
  width: 100%;
  
  @media screen and (min-width: 1220px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

BaseLayout.defaultProps = {
  maxWidth: '1120px',
  px: [15, 15, 50],
  pt: 20,
  pb: 60,
};

const Layout = ({ children, bgColor, ...props }) => (
  <Wrapper bgColor={bgColor} mt={props.mt}>
    <BaseLayout {...props}>{children}</BaseLayout>
  </Wrapper>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  mt: PropTypes.number,
};
Layout.defaultProps = {
  bgColor: 'transparent',
};

export default Layout;
