import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Card from '../../_common/components/Card';

const BlackDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
`;
const CardWrapper = styled(Box)`
  min-width: 145px;
`;
const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`;
const FlexCard = styled(Card)`
  display: flex;
  align-items: center;
`;

const TinyBoxCard = ({
  label, topText, mainText, bottomText,
}) => (
  <CardWrapper>
    <Text fontSize={13}>{label}</Text>
    <FlexCard px={10} py={10} height={100}>
      <Flex width={1} alignItems="center" flexDirection="column">
        <UpperCaseText>{topText}</UpperCaseText>
        <Flex alignItems="center" justifyContent="space-between" width={1}>
          <BlackDot />
          <Text fontSize={26}>{mainText}</Text>
          <BlackDot />
        </Flex>
        <UpperCaseText>{bottomText}</UpperCaseText>
      </Flex>
    </FlexCard>
  </CardWrapper>
);

TinyBoxCard.propTypes = {
  label: PropTypes.string,
  topText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottomText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TinyBoxCard;
