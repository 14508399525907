import moment from 'moment-timezone';
import React from 'react';
import {
  Box, Flex, Image, Text, Link,
} from 'rebass';
import styled from 'styled-components';
import HorizontalLine from '../_common/components/HorizontalLine';
import { HideDesktop, HideMobile } from '../_common/components/navigation/Menu';
import Slider from '../_common/components/Slider';
import SearchForm from './components/forms/SearchForm';
import { FACILITIES } from '../_app/constants';

const Photo = styled(Box)`
  background-image: ${props => `url(${props.url});`};
  background-size: cover;
  background-position: center;
  min-height: 520px;

  @media screen and (max-width: 52em) {
    min-height: 350px;
  }

  @media screen and (max-width: 40em) {
     min-height: 200px;

  }
`;
export const CovidLink = styled(Link)`
  color: ${props => props.theme.colors.secondaryText};
  text-decoration: underline;
  &:hover {
    font-weight: 600;
    cursor: pointer;
  }
`;

const { REACT_APP_TZ: TZ } = process.env;

const SearchPage = () => (
  <>
    <Text pt={60} fontSize="23px" textAlign="center">
      Please read our&nbsp;
      <CovidLink href="https://work.life/covid-19-policy/" target="_blank" rel="noopener noreferrer" fontSize="23px">COVID-19 policy</CovidLink>
    </Text>
    <Box pt={50}>
      <SearchForm
        initialValues={{
          date: moment.tz(TZ).format('YYYY-MM-DD'),
          capacity: '1',
          distanceSort: true,
        }}
      />
      <HorizontalLine mt={80} mb={30} />
      <Text mb={30} textAlign="center" fontSize={23}>
      Included with all our Meeting Rooms
      </Text>
      <Flex mx={-1} mb={30} flexWrap="wrap">
        {FACILITIES.map((facility, id) => (
          <Flex px={1} key={id} alignItems="center" flexDirection="column" width={[1, 1 / 5]} mb={[20, 0]}>
            <Image height={45} mb={15} src={facility.icon} />
            <Text textAlign="center" fontSize={16} color="mainOrange">{facility.name}</Text>
          </Flex>
        ))}
      </Flex>
      <Box mb={80}>
        <HideMobile>
          <Slider>
            <Photo url="/images/carousel/1.jpg" />
            <Photo url="/images/carousel/2.jpg" />
            <Photo url="/images/carousel/3.jpg" />
            <Photo url="/images/carousel/4.jpg" />
            <Photo url="/images/carousel/5.jpg" />
          </Slider>
        </HideMobile>
        <HideDesktop>
          <Slider>
            <Photo url="/images/carousel/mobile/1.jpg" />
            <Photo url="/images/carousel/mobile/2.jpg" />
            <Photo url="/images/carousel/mobile/3.jpg" />
            <Photo url="/images/carousel/mobile/4.jpg" />
            <Photo url="/images/carousel/mobile/5.jpg" />
          </Slider>
        </HideDesktop>
      </Box>
    </Box>
  </>
);

export default SearchPage;
