import styled from 'styled-components';
import { Button as RawButton } from 'rebass';
import { FONT_FAMILY } from '../../theme';

const DirectionButton = styled(RawButton)`
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: ${props => props.theme.fonts[FONT_FAMILY.HAILSR_MEDIUM]};
  background-color: transparent;
  :hover {
    color: ${props => props.theme.colors.secondaryText};
  }
  &::before {
    content: '<';
    position: relative;
    top: 50%;
    margin-right: 10px;
  }
`;

export default DirectionButton;
