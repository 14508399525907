import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Flex, Box, Text } from 'rebass';
import HorizontalLine from '../../_common/components/HorizontalLine';
import Loader from '../../_common/components/Loader';
import { FONT_FAMILY } from '../../theme';
import PaymentListItem from './PaymentListItem';

const PaymentList = ({ upcomingPayments, pastPayments, isLoading }) => (
  <Flex flexDirection="column" width={1}>
    {!isEmpty(upcomingPayments) && (
      <>
        <Text mt={70} mb={35} fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={51} color="mainOrange">
          UPCOMING PAYMENTS
        </Text>
        {upcomingPayments.map((payment, i) => (
          <PaymentListItem {...payment} key={`upcoming-payment-item-${i}`} />
        ))}
      </>
    )}
    {!isEmpty(pastPayments) && (
      <Box mt={20}>
        <HorizontalLine mt={20} mb={60} />
        <Text mb={35} fontFamily={FONT_FAMILY.VENEER_CLEAN} fontSize={51} color="mainOrange">
          PAST PAYMENTS
        </Text>
        {pastPayments.map((payment, i) => (
          <PaymentListItem {...payment} key={`past-payment-item-${i}`} />
        ))}
      </Box>
    )}
    {isLoading && <Loader fullPage={false} />}
  </Flex>
);

PaymentList.propTypes = {
  upcomingPayments: PropTypes.array.isRequired,
  pastPayments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PaymentList;
