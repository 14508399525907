import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import moment from 'moment-timezone';
import TinyBoxCard from './TinyBoxCard';

const BigText = styled(Text).attrs({
  color: 'mainOrange',
  fontSize: 24,
  mt: 14,
})``;

const { REACT_APP_TZ: TZ } = process.env;

const DateSummary = ({
  from, to, capacity, date,
}) => (
  <Box width={1} mb={50}>
    <BigText mb={20}>So your meeting room is...</BigText>
    <Flex flexWrap="wrap" width={1}>
      <Flex alignItems="center" mb={20}>
        <BigText width="80px" textAlign="center">
          on
        </BigText>
        <TinyBoxCard
          label="DATE"
          topText={moment.tz(date, TZ).format('dddd')}
          bottomText={moment.tz(date, TZ).format('YYYY')}
          mainText={moment.tz(date, TZ).format('DD MMM')}
        />
      </Flex>
      <Flex alignItems="center" mb={20}>
        <BigText width="80px" textAlign="center">
          for
        </BigText>
        <TinyBoxCard
          label="GUESTS"
          topText="up to"
          bottomText="people"
          mainText={capacity}
        />
      </Flex>
    </Flex>
    <Flex flexWrap="wrap" width={1}>
      <Flex alignItems="center" mb={20}>
        <BigText width="80px" textAlign="center">
          from
        </BigText>
        <TinyBoxCard label="CHECK-IN" mainText={from} />
      </Flex>
      <Flex alignItems="center" mb={20}>
        <BigText width="80px" textAlign="center">
          to
        </BigText>
        <TinyBoxCard label="CHECK-OUT" mainText={to} />
      </Flex>
    </Flex>
  </Box>
);

DateSummary.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  capacity: PropTypes.number,
  date: PropTypes.string,
};

export default DateSummary;
