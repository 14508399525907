import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flex, Box, Text } from 'rebass';
import moment from 'moment-timezone';
import styled from 'styled-components';
import Button, { BUTTON_VARIANT } from '../../_common/components/Button';
import { APP_ROUTES } from '../../_app/routes';
import { FONT_FAMILY } from '../../theme';

const Wrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.mainOrange};
`;

const { REACT_APP_TZ: TZ } = process.env;

const PaymentListItem = ({
  paymentDate,
  bookingDate,
  place,
  price,
  invoiceUrl,
}) => (
  <>
    <Wrapper width={1} mb={40} p={20} flexWrap={['wrap', 'wrap', 'nowrap']}>
      <Flex flexDirection="column" width={1} mr={[0, 0, 20]}>
        <Flex mb={20} mx={-10} width={1} flexWrap={['wrap', 'nowrap']}>
          <Flex flexDirection="column" width={[1, 1 / 2]} mb={[20, 0]} px={10}>
            <Text fontSize={13}>PAYMENT DATE</Text>
            <Text fontSize={21}>{moment.tz(paymentDate, TZ).format('Do MMM YYYY')}</Text>
          </Flex>
          <Flex flexDirection="column" width={[1, 1 / 2]} mr={20} px={10}>
            <Text fontSize={13}>AMOUNT</Text>
            <Text fontSize={21}>{`£${price.toFixed(2)}`}</Text>
          </Flex>
        </Flex>
        <Box width={[1, 1, 1 / 2]} mb={[20, 20, 0]}>
          <Text fontFamily={FONT_FAMILY.HAILSR_BOOK}>
            {`For meeting room booking on ${moment.tz(bookingDate, TZ).format('Do MMM YYYY')} in ${place}`}
          </Text>
        </Box>
      </Flex>
      <Flex flexDirection="column">
        <Flex width={1} justifyContent="flex-end" flexDirection="column" alignItems="flex-start">
          {invoiceUrl ? (
            <a target="_blank" href={invoiceUrl} rel="noopener noreferrer">
              <Button minWidth={214}>View invoice</Button>
            </a>
          ) : (
            <>
              <Link to={APP_ROUTES.ROOT}>
                <Button mb={20} minWidth={230}>Edit payment method</Button>
              </Link>
              <Button variant={BUTTON_VARIANT.SECONDARY}>View booking</Button>
            </>
          )}
        </Flex>
      </Flex>
    </Wrapper>
  </>
);

PaymentListItem.propTypes = {
  invoiceUrl: PropTypes.string,
  paymentDate: PropTypes.string,
  bookingDate: PropTypes.string,
  place: PropTypes.string,
  price: PropTypes.number,
};

export default PaymentListItem;
