import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../../../_app/routes';

const Badge = styled(Flex)`
  position: absolute;
  color: white;
  background: ${props => props.theme.colors.mainOrange};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  left: -20px;
  top: -10px;
  cursor: pointer;
`;

@inject('cartStore')
@observer
class Cart extends Component {
  render() {
    const { count, isPaid } = this.props.cartStore;

    if (!count || isPaid) {
      return null;
    }

    return (
      <NavLink to={APP_ROUTES.BOOKING_PAYMENT}>
        <Flex alignItems="center" justifyContent="ceter" style={{ position: 'relative' }} mx={10}>
          <Badge>{count}</Badge>
          <Image style={{ cursor: 'pointer' }} src="/images/icons/cart.svg" width={36} height="auto" />
        </Flex>
      </NavLink>
    );
  }
}

Cart.wrappedComponent.propTypes = {
  cartStore: PropTypes.shape({
    count: PropTypes.number.isRequired,
    isPaid: PropTypes.bool,
  }).isRequired,
};

export default Cart;
