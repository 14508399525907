import { action, observable, computed } from 'mobx';
import get from 'lodash/get';
import queryString from 'query-string';
import moment from 'moment-timezone';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

const PAGE_SIZE = 6;
const { REACT_APP_TZ: TZ } = process.env;


export class SearchStore {
  @observable isLoading = false;
  @observable resourcesList = [];
  @observable exactResources = [];
  @observable exactLocation = undefined;
  @observable currentResource = {};
  @observable filters = {};
  @observable error = {};
  @observable page = 1;
  @observable hasMore = false;

  @action fetchResources = async (filters) => {
    this.filters = this.mapFilters(filters);
    const query = queryString.stringify(omit(this.filters, ['date']));

    this.isLoading = true;
    this.page = 1;
    try {
      const {
        data: { resources, exactResources, exactLocation },
      } = await API(`${API_ROUTES.RESOURCES}?${query}`);
      this.resourcesList = resources;
      this.exactResources = exactResources;
      this.exactLocation = exactLocation;
      this.hasMore = this.page * PAGE_SIZE < this.resourcesCount;
    } catch (e) {
      this.error = e;
      this.resourcesList = [];
      this.hasMore = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchResourceById = async (resourceId) => {
    this.isLoading = true;
    try {
      const {
        data: { resource },
      } = await API(`${API_ROUTES.RESOURCE_BY_ID(resourceId)}`);
      this.currentResource = resource;
    } catch (e) {
      toast.error(get(e, 'errors[0].detail', 'Network error!'));
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchMoreResources = async () => {
    this.page++;
    if (this.page * PAGE_SIZE >= this.resourcesCount) {
      this.hasMore = false;
    }
  };

  @action setCurrentResource = (resource) => {
    this.currentResource = resource;
  };

  @computed
  get resourcesCount() {
    return this.resourcesList.length;
  }

  @computed
  get paginatedResources() {
    return this.resourcesList.slice(0, this.page * PAGE_SIZE);
  }

  mapFilters = filters => ({
    ...filters,
    from: moment.tz(`${filters.date} ${filters.from}`, TZ).toISOString(),
    to: moment.tz(`${filters.date} ${filters.to}`, TZ).toISOString(),
  });
}

export default new SearchStore();
