import { action, extendObservable } from 'mobx';

import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const initialState = {
  isLoading: false,
  error: null,
  success: null,
};

export class ContactStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action createContact = async (values) => {
    try {
      this.isLoading = true;
      await API.post(API_ROUTES.CONTACTS, values);
      this.success = true;
    } catch (e) {
      this.error = e.message;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new ContactStore();
