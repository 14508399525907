import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'rebass';
import Modal from '../../_common/components/Modal';
import BookingEditForm from './forms/BookingEditForm';

const modalContentStyles = { maxWidth: '400px', padding: '25px 30px' };

const EditPaymentModal = ({
  initialValues, isOpen, onSubmit, onCancel,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    customStyles={{ content: modalContentStyles }}
    onRequestClose={onCancel}
  >
    <Text mb={20} fontSize={20}>
      Edit booking
    </Text>
    <BookingEditForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} />
  </Modal>
);

EditPaymentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default EditPaymentModal;
