import styled, { css } from 'styled-components';

const StyledAnchor = styled.a`
  color: ${props => (props.color || props.theme.colors.primaryText)};
  ${props => props.fontSize && css`
    font-size: ${props.fontSize}px;
  `}
  ${props => props.fontFamily && css`
    font-family: ${props.fontFamily};
  `}
  ${props => props.hoverEffect && css`
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `}
`;

StyledAnchor.defaultProps = {
  fontSize: '11px',
};

export default StyledAnchor;
