import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  Text, Flex, Image, Box,
} from 'rebass';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Modal from '../../_common/components/Modal';
import Button from '../../_common/components/Button';
import Location from '../../_common/components/Location';
import CardTitle from '../../signIn/components/CardTitle';
import { FACILITIES } from '../../_app/constants';
import { getSymbolFromCurrency } from '../../utils/currency';
import { convertMetersToMiles } from '../../utils/distance';

const Close = styled(Image)`
  cursor: pointer;
  transition: opacity 0.4s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`;

const BgPhoto = styled(Box)`
  background-image: ${props => `url(${props.url});`};
  background-size: cover;
  background-position: center;
  min-height: 200px;
`;

const CapacityText = styled(Text)`
  white-space: nowrap;
  text-transform: uppercase;
`;

const ResourceDetailsModal = ({
  onClose,
  redirectPath,
  isOpen,
  resource: {
    name,
    district,
    address,
    distance,
    description,
    pricePerHour = {},
    photo,
    additionalInfo,
  },
}) => {
  const capacity = get(additionalInfo, 'capacity');
  const net = Number(get(pricePerHour, 'net', 0)).toFixed(2);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customStyles={{
        content: { maxWidth: '1121px', border: '1px solid #f8b674' },
      }}
      onRequestClose={onClose}
    >
      <Flex flexDirection={['column', 'column', 'row']}>
        <Box width={[1, 1, 1 / 3]} p="10px 20px 20px 10px">
          <Close
            onClick={onClose}
            height={21}
            src="/images/icons/close-button@2x.png"
            alt="close"
          />
          <Box width={1} pl={[0, 22]}>
            <Flex
              mt={15}
              mb={25}
              justifyContent="space-between"
              width={1}
              alignItems="flex-end"
            >
              <CardTitle>{name}</CardTitle>
              <CapacityText ml={10} fontSize={18}>{`${capacity} ${capacity === 1 ? 'person' : 'people'}`}</CapacityText>
            </Flex>
            <Flex width={1} flexDirection="column">
              <Location mb={10}>{district}</Location>
              <Text color="secondaryText">
                {address}
              </Text>
              {distance !== undefined && <Text fontSize={13}>{`${convertMetersToMiles(distance)} miles away`}</Text>}
              <Text mt={25}>
                What makes this room special:
              </Text>
              <Text fontSize={14} lineHeight={1.43}>
                {description}
              </Text>
              <Text mt={20}>
                {"What's included:"}
              </Text>
              <Flex width={1} flexWrap="wrap" justifyContent="space-around">
                {FACILITIES.map((facility, id) => (
                  <Flex
                    key={`facility-${id}`}
                    alignItems="center"
                    flexDirection="column"
                    width="auto"
                    mt={10}
                  >
                    <Flex justifyContent="center" alignItems="center" style={{ height: 30 }}>
                      <Image style={{ height: 'auto', maxHeight: 30, maxWidth: 40 }} src={facility.icon} mb={10} />
                    </Flex>
                    <Text
                      width={1}
                      lineHeight={1}
                      textAlign="center"
                      fontSize={[10, 13, 13]}
                      color="mainOrange"
                    >
                      {facility.shortName}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex
              width={1}
              justifyContent="space-between"
              mt={40}
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <Flex flexDirection="column" alignItems="center">
                <Text
                  fontSize={52}
                  fontFamily="VeneerClean, serif"
                  lineHeight={1}
                >
                  {`${getSymbolFromCurrency(pricePerHour.currency)}${net}`}
                </Text>
                <Text fontSize={12} fontWeight="bold" letterSpacing="-0.4px">
                  +VAT PER HOUR
                </Text>
              </Flex>
              <Link to={redirectPath}>
                <Button mt={10}>Book it!</Button>
              </Link>
            </Flex>
          </Box>
        </Box>
        <BgPhoto
          url={get(photo, 'default.url', null) || '/images/carousel-item.png'}
          width={[1, 1, 2 / 3]}
        />
      </Flex>
    </Modal>
  );
};

ResourceDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectPath: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  resource: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    distance: PropTypes.number,
    district: PropTypes.string,
    description: PropTypes.string,
    pricePerHour: PropTypes.object,
    photo: PropTypes.object,
    additionalInfo: PropTypes.object,
  }),
};

export default ResourceDetailsModal;
