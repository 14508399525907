import { Flex } from 'rebass';
import styled from 'styled-components';

const VerticalDottedLine = styled(Flex)`
  width: 5px;
  border-left: 5px ${props => props.theme.colors.mainOrange} dotted;
  height: 100%;
`;

export default VerticalDottedLine;
