import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 1px solid ${props => props.theme.colors.mainOrange};
    background: #fff;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    background: url('/images/icons/tick.png') no-repeat center center;
    background-size: cover;
    width: 20px;
    height: 20px;
    left: 2px;
    top: calc(50% - 15px);
  }
`;

const FormCheckbox = ({
  label, input, id, meta, ...props
}) => (
  <Flex my={20} fontSize={13}>
    <Checkbox type="checkbox" {...props} {...input} id={id} />
    <label htmlFor={id} ml={15}>
      {label}
    </label>
  </Flex>
);

FormCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default FormCheckbox;
