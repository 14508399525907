import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.fullPage
    ? `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(33, 40, 64, 0.65);
    z-index: 1000;
  `
    : `
    margin: 30px 0;
  `)}
`;

const Loader = ({ fullPage }) => (
  <LoaderWrapper fullPage={fullPage}>
    <img src="/images/icons/loader.gif" alt="Loading..." />
  </LoaderWrapper>
);

Loader.propTypes = {
  fullPage: PropTypes.bool,
};
Loader.defaultProps = {
  fullPage: true,
};

export default Loader;
