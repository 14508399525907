import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import SignInCard from './components/SignInCard';
import SignUpCard from './components/SignUpCard';
import DirectionButton from '../_common/components/DirectionButton';
import { APP_ROUTES } from '../_app/routes';

@inject('authStore', 'routerStore', 'userStore')
@observer
class SignInPage extends Component {
  render() {
    const {
      signIn,
      onForgotPassword,
      signUp,
      validateUser,
    } = this.props.authStore;
    return (
      <Flex width={[1, 1, 1, 950]} mt={20} flexDirection="column" mx="auto">
        <Flex width={1} flexWrap="wrap">
          <SignInCard actions={{ signIn, onForgotPassword }} />
          <SignUpCard
            onSubmit={signUp}
            keepGuestData={this.props.userStore.keepGuestData}
            validateUser={validateUser}
          />
        </Flex>
        <DirectionButton
          px={0}
          onClick={() => this.props.routerStore.push(APP_ROUTES.BOOKING_PAYMENT)
          }
        >
          Take me back
        </DirectionButton>
      </Flex>
    );
  }
}

SignInPage.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    onForgotPassword: PropTypes.func,
    validateUser: PropTypes.func,
  }).isRequired,
  routerStore: PropTypes.shape({
    push: PropTypes.func,
  }),
  userStore: PropTypes.shape({
    keepGuestData: PropTypes.func,
  }),
};

export default SignInPage;
