/* eslint-disable no-underscore-dangle */
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { MEETING_ROOM_EMAIL } from '../_app/constants';
import { APP_ROUTES } from '../_app/routes';
import BookingListItem from '../_common/components/BookingListItem';
import Button from '../_common/components/Button';
import Card from '../_common/components/Card';
import StyledAnchor from '../_common/components/StyledAnchor';
import VerticalDottedLine from '../_common/components/VerticalDottedLine';
import { FONT_FAMILY } from '../theme';

const Wrapper = styled(Flex)`
  height: 100%;
  background-image: url('/images/group@2x.png');
  background-position: bottom right;
  background-size: 538px;
  background-repeat: no-repeat;
`;

const VerticalDottedLineContainer = styled(Box)`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  @media screen and (max-width: 40em) {
    display: none;
  }
`;

@inject('cartStore')
@observer
class BookingSuccessPage extends Component {
  componentWillUnmount() {
    const { isPaid, clearCart } = this.props.cartStore;
    if (isPaid) {
      clearCart();
    }
  }
  render() {
    const { isAuthenticated } = this.props;
    const { isPaid, items } = this.props.cartStore;

    if (!isPaid && isEmpty(items)) {
      return (
        <Redirect to={APP_ROUTES.ROOT} />
      );
    }

    if (isAuthenticated) {
      return (
        <Card width={1} mt={60} minHeight={491} px={20} py={20} style={{ display: 'flex' }}>
          <Flex flexDirection="column" flex={1}>
            <Text fontSize={38} fontFamily={FONT_FAMILY.VENEER_CLEAN}>{'TO CONFIRM WHAT YOU\'VE BOOKED'}</Text>
            <Flex mb={30} mx={[0, -30]} flexWrap="wrap" style={{ position: 'relative' }}>
              <VerticalDottedLineContainer>
                <VerticalDottedLine />
              </VerticalDottedLineContainer>
              {items.map(({ space, resource, ...item }) => (
                <Flex width={[1, 1 / 2]} px={[0, 30]} mt={30} key={item._id}>
                  <BookingListItem
                    {...item}
                    {...space}
                    {...resource}
                  />
                </Flex>
              ))}
            </Flex>
            <Text mt="auto" fontSize={13}>You can edit or cancel your bookings up to 48 hours</Text>
            <Text fontSize={13}>
              {'prior by emailing'}
              {' '}
              <StyledAnchor fontSize={13} href={`mailto:${MEETING_ROOM_EMAIL}`}>{MEETING_ROOM_EMAIL}</StyledAnchor>
            </Text>
            <Link to={APP_ROUTES.ROOT}>
              <Button mt={20}>Make another booking</Button>
            </Link>
          </Flex>
        </Card>
      );
    }

    return (
      <Card width={1} mt={60} height={491} px={0} py={0}>
        <Wrapper flexDirection="row">
          <Flex width={[1, 1, 1 / 2]} flexDirection="column" justifyContent="center" p={20}>
            <Text mb={20} fontSize={38} fontFamily={FONT_FAMILY.VENEER_CLEAN}>{'WE\'VE SENT YOU AN EMAIL WITH CONFIRMATION OF YOUR BOOKINGS'}</Text>
            <Link to={APP_ROUTES.ROOT}>
              <Button>Make another booking</Button>
            </Link>
          </Flex>
        </Wrapper>
      </Card>
    );
  }
}

BookingSuccessPage.wrappedComponent.propTypes = {
  cartStore: PropTypes.shape({
    isPaid: PropTypes.bool,
    items: PropTypes.array.isRequired,
    clearCart: PropTypes.func.isRequired,
  }),
  isAuthenticated: PropTypes.bool,
};

export default BookingSuccessPage;
