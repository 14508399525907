import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';
import { Box } from 'rebass';
import styled from 'styled-components';
import BaseLayout from './layouts/BaseLayout';
import NavBar from './components/navigation/NavBar';
import { SUBHEADER_TITLES } from '../_app/constants';

const Cover = styled(Box)`
  position: fixed;
  height: 400px;
  width: 100%;
  top: -400px;
  background-color: ${props => props.theme.colors.secondaryBg};
  z-index: 1000;
`;

@inject('authStore')
@observer
class AppRoute extends React.Component {
  render() {
    const {
      layout: Layout,
      component: Component,
      isPrivate,
      ...routeProps
    } = this.props;
    const { isAuthenticated } = this.props.authStore;

    return (
      <Route
        {...routeProps}
        render={(props) => {
          const titles = SUBHEADER_TITLES[routeProps.path];

          if (!isAuthenticated && isPrivate) {
            return <Redirect to="/" />;
          }
          return (
            <>
              <Cover />
              <NavBar titles={titles} />
              <Layout pt={67}>
                <Component
                  {...routeProps}
                  {...props}
                  isAuthenticated={isAuthenticated}
                />
              </Layout>
            </>
          );
        }}
      />
    );
  }
}

AppRoute.wrappedComponent.propTypes = {
  component: PropTypes.func,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
  isPrivate: PropTypes.bool,
  navBar: PropTypes.func,
  path: PropTypes.string,
  layout: PropTypes.func,
};

AppRoute.wrappedComponent.defaultProps = {
  isPrivate: false,
  layout: BaseLayout,
};

export default AppRoute;
