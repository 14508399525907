import times from 'lodash/times';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import queryString from 'query-string/index';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import { APP_ROUTES } from '../../_app/routes';
import Button, { BUTTON_VARIANT } from '../../_common/components/Button';
import DatePicker from '../../_common/components/forms/DatePicker';
import { AbsolutePositionedErrorsForm } from '../../_common/components/forms/FormError';
import FormSelect from '../../_common/components/forms/FormSelect';
import GooglePlacesAutocomplete from '../../_common/components/forms/GoogleAutocomplete';
import {
  composeValidators,
  mustBe1hourDuration,
  mustBeLaterThanCurrentHour,
  required,
} from '../../utils/formValidators';
import { BOOKING_HOURS } from '../../utils/hoursHelpers';

const CAPACITY = times(13, n => n + 1).map(n => ({
  value: n.toString(),
  label: `${n <= 12 ? n : 12} ${n === 1 ? 'person' : (n <= 12 ? 'people' : '+')}`,
}));

const bookingHours = BOOKING_HOURS(9, 17.5);

const SearchResultsForm = ({ history: { push }, initialValues }) => (
  <Form
    initialValues={{ from: '09:00', to: '10:00', ...initialValues }}
    onSubmit={(values) => {
      if (!values.location) {
        delete values.placeId;
      }
      push(APP_ROUTES.SEARCH(`${queryString.stringify({ ...values })}`));
    }}
    render={({ handleSubmit, form: { change } }) => (
      <AbsolutePositionedErrorsForm onSubmit={handleSubmit}>
        <Flex alignItems="center" flexDirection="column">
          <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
            <Text color="mainOrange" fontSize={19} mr={10} mb={30}>
              {'So, you\'re looking for a room in'}
            </Text>
            <Box width={120} mb={40}>
              <GooglePlacesAutocomplete
                name="location"
                placeholder="Location"
                formChange={change}
                placeId={initialValues.placeId}
              />
            </Box>
            <Flex alignItems="center" justifyContent="center" mb={40}>
              <Text color="mainOrange" fontSize={19} mx={10}>
                for
              </Text>
              <Field
                name="capacity"
                width={120}
                component={FormSelect}
                validate={required}
                options={CAPACITY}
              />
            </Flex>
            <Flex alignItems="center" justifyContent="center" mb={40}>
              <Text color="mainOrange" fontSize={19} mx={10}>
                on
              </Text>
              <Box width={120}>
                <Field
                  name="date"
                  component={DatePicker}
                  disableDayFn={date => moment.utc(date).isBefore(moment.utc().format('YYYY-MM-DD'))}
                  disableWeekends
                />
              </Box>
            </Flex>
            <Flex alignItems="center" justifyContent="center" mb={40}>
              <Text color="mainOrange" fontSize={19} mx={10}>
                from
              </Text>
              <Field
                name="from"
                width={120}
                component={FormSelect}
                validate={composeValidators(
                  required,
                  mustBeLaterThanCurrentHour,
                )}
                options={bookingHours}
              />
            </Flex>
            <Flex alignItems="center" justifyContent="center" mb={40}>
              <Text color="mainOrange" fontSize={19} mx={10}>
                to
              </Text>
              <Box mr={10}>
                <Field
                  name="to"
                  width={120}
                  component={FormSelect}
                  validate={composeValidators(required, mustBe1hourDuration)}
                  options={bookingHours}
                />
              </Box>
            </Flex>
          </Flex>
          <Button variant={BUTTON_VARIANT.SECONDARY} mb={20} type="submit">
            Amend details
          </Button>
        </Flex>
      </AbsolutePositionedErrorsForm>
    )}
  />
);

SearchResultsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchResultsForm);
