import { action, extendObservable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import auth from './authStore';

const initialState = {
  isLoading: false,
  profile: {},
  error: null,
  guest: {},
};

export class UserStore {
  constructor() {
    extendObservable(this, initialState);
  }
  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action
  fetchProfile = async () => {
    if (!auth.isAuthenticated) return;
    this.isLoading = true;
    try {
      const {
        data: { profile = {} },
      } = await API(API_ROUTES.PROFILE);
      this.profile = profile;
    } catch (e) {
      toast.error(e.error || e.message || "Couldn't load profile.");
    } finally {
      this.isLoading = false;
    }
  };

  @action updateProfile = async (profileData) => {
    const company = profileData.company || '';
    this.isLoading = true;
    try {
      const {
        data: { profile },
      } = await API.patch(API_ROUTES.PROFILE, { ...profileData, company });
      this.profile = { ...this.profile, ...profile };
      toast.info('Profile updated.');
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      return { [FORM_ERROR]: get(e, 'message') };
    } finally {
      this.isLoading = false;
    }
  };

  @action keepGuestData = (guestData) => {
    this.guest = guestData;
  };

  @action fetchGuestData = () => this.guest;
}

export default new UserStore();
