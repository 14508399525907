import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Router, Switch, Redirect, Route,
} from 'react-router-dom';
import { inject } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import { APP_ROUTES } from './_app/routes';
import ScrollToTop from './ScrollToTop';
import SearchPage from './search';
import SearchResults from './searchResults';
import ResourceBookingConfirmation from './resourceBookingConfirmation';
import BookingPayment from './bookingPayment';
import SignInPage from './signIn';
import BookingSuccessPage from './bookingSuccess';
import PaymentsPage from './payments';
import AccountPage from './account';
import BookingsPage from './bookings';
import BookingEditPage from './bookingEdit';
import ResetPasswordPage from './resetPassword';
import AppRoute from './_common/AppRoute';
import Footer from './_common/components/Footer';
import SignInLayout from './_common/layouts/SignInLayout';
import ImageLayout from './_common/layouts/ImageLayout';
import { history } from './_app/history';
import redirectAuthUser from './hoc/auth';
import { initializeGTM } from './_app/googleTagManager';
import { initializeGAPageView } from './_app/googleAnalytics';

import './styles/toastify.css';

@inject('cartStore')
class App extends Component {
  componentDidMount() {
    this.props.cartStore.fetchCart();
    initializeGTM();
    initializeGAPageView(history);
  }
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <AppRoute exact path={APP_ROUTES.ROOT} component={SearchPage} />
            <AppRoute
              exact
              path={APP_ROUTES.SEARCH()}
              component={SearchResults}
            />
            <AppRoute
              exact
              path={APP_ROUTES.RESOURCE_BOOKING_CONFIRMATION()}
              component={ResourceBookingConfirmation}
              layout={ImageLayout}
            />
            <AppRoute
              exact
              path={APP_ROUTES.BOOKING_PAYMENT}
              component={BookingPayment}
              layout={ImageLayout}
            />
            <AppRoute
              exact
              path={APP_ROUTES.SIGN_IN}
              component={redirectAuthUser(SignInPage)}
              layout={SignInLayout}
            />
            <AppRoute
              exact
              path={APP_ROUTES.BOOKINGS_SUCCESS}
              component={BookingSuccessPage}
              layout={ImageLayout}
            />
            <AppRoute
              exact
              path={APP_ROUTES.RESET_PASSWORD}
              component={ResetPasswordPage}
              layout={ImageLayout}
            />

            {/* Private routes */}
            <AppRoute
              isPrivate
              exact
              path={APP_ROUTES.ACCOUNT}
              component={AccountPage}
            />
            <AppRoute
              isPrivate
              exact
              path={APP_ROUTES.BOOKINGS}
              component={BookingsPage}
            />
            <AppRoute
              isPrivate
              exact
              path={APP_ROUTES.BOOKINGS_BY_ID()}
              component={BookingEditPage}
            />
            <AppRoute
              isPrivate
              exact
              path={APP_ROUTES.PAYMENTS}
              component={PaymentsPage}
            />

            <Route path="*" render={() => <Redirect to={APP_ROUTES.ROOT} />} />
          </Switch>
        </ScrollToTop>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          toastClassName="yellowbg"
        />
      </Router>
    );
  }
}

App.wrappedComponent.propTypes = {
  cartStore: PropTypes.shape({
    fetchCart: PropTypes.func.isRequired,
  }).isRequired,
};

export default App;
