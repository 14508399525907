export const APP_ROUTES = {
  ROOT: '/',
  SEARCH: (query = null) => (query ? `/search?${query}` : '/search'),
  RESOURCE_BOOKING_CONFIRMATION: resourceId => (resourceId
    ? `/resources-confirmation/${resourceId}`
    : '/resources-confirmation/:resourceId'),
  BOOKING_PAYMENT: '/booking-payment',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  BOOKINGS: '/bookings',
  BOOKINGS_BY_ID: id => (id ? `/bookings/${id}` : '/bookings/:bookingId'),
  BOOKINGS_SUCCESS: '/booking-success',
  ACCOUNT: '/account',
  PAYMENTS: '/payments',
  RESET_PASSWORD: '/reset-password/:token',
};

export const API_ROUTES = {
  RESOURCES: '/resources',
  RESOURCE_BY_ID: id => `/resources/${id}`,
  ORDERS: id => (id ? `/orders/${id}` : '/orders'),
  BOOKINGS: id => (id ? `/bookings/${id}` : '/bookings'),
  SIGN_IN: '/sign_in',
  SIGN_UP: '/sign_up',
  VALIDATE: '/sign_up/validate',
  PROFILE: '/profile',
  ADD_ORDER: '/orders/add_to_cart',
  DELETE_ORDER: id => `/orders/remove_from_cart/${id}`,
  RESET_PASSWORD: token => (token ? `/reset-password/${token}` : '/reset-password'),
  PAYMENT_METHOD: bookingId => `/bookings/${bookingId}/payment_method`,
  UPDATE_BOOKING: bookingId => `/bookings/paid/${bookingId}`,
  DISCOUNT_VALIDITY_CHECK: code => `/orders/codes/${code}`,
  CONTACTS: '/contacts/',
};
