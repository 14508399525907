import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const Wrapper = styled(Box)`
  background-color: ${props => props.bgColor};
  box-shadow: 4px 4px 0px 0px rgba(250, 190, 121, 1);
  border: 1px solid rgba(250, 190, 121, 1);
  ${props => props.height && css`
    height: ${props.height}px;
  `}
  ${props => props.minHeight && css`
    min-height: ${props.minHeight}px;
  `}
`;

Wrapper.defaultProps = {
  bgColor: '#fff',
  boxShadow: '#fabe79',
};

const Card = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Card.defaultProps = {
  px: 30,
  py: 25,
};

export default Card;
