import React from 'react';
import { Flex } from 'rebass';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import FormError from './FormError';
import Label from './FormLabel';
import Card from '../Card';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? '#fabe79'
      : state.isFocused
        ? 'rgba(250, 190, 121, 0.5)'
        : 'transparent',
    padding: 10,
    ':active': {
      backgroundColor: '#fabe79',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #fabe79',
    borderRadius: 0,
    paddingTop: '2px',
    paddingBottom: '2px',
    boxShadow: state.isFocused ? '0 0 5px #fabe79' : 'none',
    '&:hover': {
      border: '1px solid #fabe79',
    },
  }),
  menuList: provided => ({
    ...provided,
    padding: '0px',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 10,
    boxShadow: 'none',
  }),
};

const MenuList = props => (
  <Card px={0} py={0}>
    <components.MenuList {...props}>{props.children}</components.MenuList>
  </Card>
);
MenuList.propTypes = {
  children: PropTypes.node,
};
const IndicatorSeparator = () => null;

const Select = ({
  children, input, meta, options, label, width, ...props
}) => {
  const [selectedValue, setSelectedValue] = React.useState(null);
  React.useEffect(() => {
    const value = get(options.filter(option => input.value === option.value), '[0]', null);
    setSelectedValue(value);
  }, [input.value]);

  return (
    <Flex flexDirection="column" width={width}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        {...input}
        {...props}
        options={options}
        styles={customStyles}
        onChange={(e) => {
          input.onChange(e.value);
          if (props.onChange) {
            props.onChange(e.value);
          }
        }}
        value={selectedValue}
        components={{ IndicatorSeparator, MenuList }}
        isSearchable={false}
      />
      <FormError meta={meta} />
    </Flex>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  width: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

Select.defaultProps = {
  width: 169,
  options: [],
};

export default Select;
