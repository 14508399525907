import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Flex } from 'rebass';
import TextInput from '../../../_common/components/forms/TextInput';
import { Error } from '../../../_common/components/forms/FormError';
import Button from '../../../_common/components/Button';
import {
  required,
  composeValidators,
  passwordsMustMatch,
  passwordLength,
} from '../../../utils/formValidators';

const SignUpLastStepForm = ({ onSubmit, onCancel, initialValues }) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError }) => (
      <form onSubmit={handleSubmit}>
        <>
          <Field
            label="Password"
            name="password"
            type="password"
            component={TextInput}
            validate={composeValidators(required, passwordLength(6))}
          />
          <Field
            label="Confirm password"
            name="passwordConfirmation"
            type="password"
            component={TextInput}
            validate={composeValidators(required, passwordsMustMatch)}
          />
        </>
        {submitError && <Error>{submitError}</Error>}
        <Flex width={1} mt={30}>
          <Button type="submit" mr={15} mb={20} py="7px">
            Finish registration
          </Button>
          <Button
            type="button"
            mr={15}
            mb={20}
            py="7px"
            onClick={onCancel}
          >
            Back
          </Button>
        </Flex>
      </form>
    )}
  />
);

SignUpLastStepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default SignUpLastStepForm;
