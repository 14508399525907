import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import PaymentList from './components/PaymentList';
import Button from '../_common/components/Button';

class PaymentPage extends Component {
  render() {
    const {
      isLoading, upcomingPayments, pastPayments, links,
    } = this.props;
    return (
      <Flex flexDirection="column" alignItems="center">
        <PaymentList
          upcomingPayments={upcomingPayments}
          pastPayments={pastPayments}
          isLoading={isLoading}
        />
        {links.next && (
          <Button my={80} onClick={() => {}}>
            Load more
          </Button>
        )}
      </Flex>
    );
  }
}

// TMP MOCK DATA
PaymentPage.defaultProps = {
  upcomingPayments: [
    {
      paymentDate: '2021-12-11',
      bookingDate: '2023-07-13',
      place: 'Warsaw',
      price: 13,
    },
  ],
  pastPayments: [
    {
      invoiceUrl: 'https://google.com',
      paymentDate: '2011-11-11',
      bookingDate: '2012-04-13',
      place: 'London',
      price: 200,
    },
    {
      invoiceUrl: 'https://google.com',
      paymentDate: '2013-09-11',
      bookingDate: '2014-01-13',
      place: 'LA',
      price: 600,
    },
  ],
  isLoading: false,
  links: {},
};

PaymentPage.propTypes = {
  upcomingPayments: PropTypes.array,
  pastPayments: PropTypes.array,
  isLoading: PropTypes.bool,
  links: PropTypes.object,
};

export default PaymentPage;
