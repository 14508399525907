import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormError from './FormError';
import Label from './FormLabel';

class StripeInput extends Component {
  handleChange = (changeObj) => {
    this.props.onChange(changeObj.empty ? undefined : changeObj);
  };

  render() {
    const {
      component: ComposedComponent,
      placeholder,
      label,
      meta,
    } = this.props;
    return (
      <Fragment>
        <Label>{label}</Label>
        <ComposedComponent
          placeholder={placeholder}
          style={{
            base: {
              fontSize: '18px',
            },
            invalid: {
              color: 'red',
            },
          }}
          className="stripe-input"
          onChange={this.handleChange}
        />
        <FormError meta={meta} />
      </Fragment>
    );
  }
}

StripeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
};

StripeInput.defaultProps = {
  placeholder: '',
  label: '',
};

export default StripeInput;
