import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Flex, Image } from 'rebass';
import styled from 'styled-components';
import Modal from '../../_common/components/Modal';
import { FONT_FAMILY } from '../../theme';
import SignInForm from './forms/SignInForm';
import ForgotPasswordForm from './forms/ForgotPaswordForm';
import CardTitle from './CardTitle';
import StyledAnchor from '../../_common/components/StyledAnchor';

export const Close = styled(Image)`
  cursor: pointer;
  transition: opacity 0.4s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`;

const modalContentStyles = { maxWidth: '451px', padding: '25px 30px' };

const SignInModal = ({
  onClose,
  actions: { signIn, onForgotPassword },
  isOpen,
}) => {
  const [showForgotPasswordForm, setForgotPasswordForm] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customStyles={{ content: modalContentStyles }}
      onRequestClose={onClose}
    >
      <Flex width={1} justifyContent="space-between" alignItems="center">
        <CardTitle>Login</CardTitle>
        <Close
          onClick={onClose}
          height={30}
          src="/images/icons/close-button@2x.png"
          alt="close"
        />
      </Flex>
      <Text
        fontSize={18}
        color="secondaryText"
        mt={10}
        mb={25}
      >
        {showForgotPasswordForm
          ? 'Forgot password?'
          : 'Sign in for speedy access:'}
      </Text>

      {!showForgotPasswordForm ? (
        <SignInForm onSubmit={signIn} toggleForm={setForgotPasswordForm} />
      ) : (
        <ForgotPasswordForm
          onSubmit={onForgotPassword}
          toggleForm={setForgotPasswordForm}
        />
      )}
      <Flex flexWrap="wrap" alignItems="center" mt={40} fontSize={12}>
        <Text fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} mr="4px">By logging in you agree to our</Text>
        <StyledAnchor fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} href="https://work.life/memberterms/meetingrooms">Terms of Service</StyledAnchor>
        <Text fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} mx="4px">and</Text>
        <StyledAnchor fontSize={12} fontFamily={FONT_FAMILY.HAILSR_BOOK} href="https://work.life/privacy/">Privacy Policy</StyledAnchor>
      </Flex>
    </Modal>
  );
};

SignInModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

export default SignInModal;
